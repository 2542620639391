import React, { Component } from 'react';
import Drawer from '../../components/drawer';
import { Grid, Link, Button } from '@material-ui/core';
import Axios from 'axios';
import MUIDataTable from 'mui-datatables';

class Chapters extends Component {
	constructor(props) {
		super(props);
		this.state = {
			chapters: [],
			loading: false,
			loaded: false
		};
	}
	componentDidMount() {
		this.setState({ loading: true }, () => {
			Axios.get('/api/chapters').then((json) => {
				const chapters = json.data.map((v) => {
					const tmp = v;
					tmp['ops'] = `/chapters/${tmp['id']}/edit`;
					return tmp;
				});
				this.setState({ chapters, loaded: true, loading: false });
			});
		});
	}
	render() {
		const { chapters } = this.state;
		const that = this;
		const columns = [
			{
				name: 'id',
				label: 'Chapter ID'
			},
			{
				name: 'name',
				label: 'Chapter name'
			},
			{
				name: 'ops',
				label: 'Operations',
				options: {
					filter: false,
					sort: false,
					customBodyRender: function(value) {
						return (
							<Link
								component="button"
								variant="button"
								onClick={() => {
									that.props.history.push(value);
								}}
							>
								Edit
							</Link>
						);
					}
				}
			}
		];
		var options = {
			sort: true,
			search: true,
			print: true,
			columns: true,
			download: true,
			selectableRows: false,
			filter: true,
			filterType: 'dropdown',
			responsive: 'stacked'
		};
		return (
			<Grid container className="backgrounded">
				<Drawer auth={this.props.auth}>
					<Grid xs={12} style={{ marginBottom: 20, textAlign: 'right' }}>
						<Button
							variant="raised"
							color="primary"
							onClick={() => {
								this.props.history.push('/chapters/add');
							}}
						>
							Add new Chapter
						</Button>
					</Grid>
					<Grid xs={12}>
						<MUIDataTable title={'Chapter List'} data={chapters} columns={columns} options={options} />
					</Grid>
				</Drawer>
			</Grid>
		);
	}
}

export default Chapters;
