import React, { Component } from "react";
import Drawer from "../../components/drawer";
import moment from "moment";
import XlsExport from 'xlsexport'
import {
  Grid,
  Paper,
  CircularProgress,
  Button,
  Typography,
} from "@material-ui/core";
import Axios from "axios";
import NonSavedStudentsTable from "./NonSavedStudnts";

export default class Wordpress extends Component {
  state = {
    count: 0,
    loading: false,
    finished: false,
    notSavedStudents: [],
    errors: [],
    status: null,
  };

  export_logs(errors) {
    const url = window.URL.createObjectURL(
      new Blob([errors.reduce((res, curr) => `${res}\n${curr}`, "")])
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `error-logs-${moment().format("DD-MM-YYYY-HH:mm").toString()}.txt`
    );
    document.body.appendChild(link);
    link.click();
  }

  confirm = async () => {
    const {
      match: { params: { id } },
    } = this.props;
    this.setState({ loading: true, finished: false });
    const res = await Axios.get(`/api/orm/wordpress/${id}`);
    const { data } = res;
    const { count, notSavedStudents, errors, alreadyExported } = data;
    if(!!data.success) {
      this.setState({
        count,
        loading: false,
        finished: true,
        notSavedStudents,
        errors,
        alreadyExported,
        status: 'ok',
      }, () => {
        var buffer = new XlsExport(data.rows);
        buffer.exportToCSV(`job-${id}-${moment().format("DD-MM-YYYY-HH:mm").toString()}.xls`)
      });
    } else {
      this.setState({
        loading: false,
        finished: true,
        status: 'no',
        errors,
      });
    }
  };

  render() {
    const {
      loading,
      finished,
      count,
      notSavedStudents,
      errors,
      alreadyExported,
      status
    } = this.state;
    const {
      match: { params },
    } = this.props;

    return (
      <Grid container className="backgrounded">
        <Drawer auth={this.props.auth}>
          <Grid container md={12}>
            <Grid item md={12} alignItems="center" justify="center">
              <Typography variant="headline" style={{ marginBottom: 20 }}>
                This will export the Job "{params.id}" to wordpress as approved
                prospects.
              </Typography>
              <Button
                disabled={loading}
                color="primary"
                variant="raised"
                onClick={this.confirm}
                style={{ marginBottom: 20, marginRight: 10 }}
              >
                Confirm
              </Button>
              {loading && <CircularProgress />}
              {finished && [
                <Typography variant="headline" style={{ marginBottom: 20 }}>
                  {status === "ok" ? (
                    <>
                      Export to wordpress went ok, {count} were exported.{" "}
                      {notSavedStudents.length} student(s) could not be saved
                      for missing data, and {alreadyExported.length} already in
                      WordPress!
                    </>
                  ) : (
                    <>
                      Export to wordpress has failed! Please check errors below and try again ...
                    </>
                  )}
                </Typography>,
              ]}
              <Button
                disabled={loading}
                color="primary"
                variant="raised"
                onClick={() => {
                  window.history.back();
                }}
                style={{ marginBottom: 20, marginRight: 10 }}
              >
                Back to Job
              </Button>
              {notSavedStudents.length > 0 ? (
                <NonSavedStudentsTable data={notSavedStudents} />
              ) : null}
              {errors.length > 0 ? (
                <>
                  <Paper
                    style={{
                      paddingTop: "10px",
                      backgroundColor: "#aaa",
                      borderTopRightRadius: "20px",
                      borderTopLeftRadius: "20px",
                      marginTop: "10px",
                    }}
                    elevation={12}
                  >
                    <Typography
                      variant="h5"
                      align="center"
                      style={{ color: "#fff" }}
                    >
                      Error Logs
                    </Typography>
                    <ul
                      className="console"
                      style={{
                        padding: "30px",
                        backgroundColor: "#000",
                        color: "#03A062",
                        fontFamily: "courier new",
                        overflowX: "auto",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {errors.map((error, idx) => (
                        <li key={idx}>{error}</li>
                      ))}
                    </ul>
                  </Paper>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => this.export_logs(errors)}
                  >
                    Download Logs
                  </Button>
                </>
              ) : null}
            </Grid>
          </Grid>
        </Drawer>
      </Grid>
    );
  }
}