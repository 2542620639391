import React from 'react'
import AuthHelpers from '../components/AuthHelperMethods';

export default class Logout extends React.Component {

    componentWillMount() {
        const Auth = new AuthHelpers();
        Auth.logout().then(() => {
            this.props.history.replace('/login');
        });
    }
    render() {
        return null;
    }

}