import React, { Component } from 'react';
import Drawer from '../../components/drawer';
import Paper from '@material-ui/core/Paper';
import CSVReader from 'react-csv-reader';
import {
	Stepper,
	Step,
	StepLabel,
	StepContent,
	Typography,
	CircularProgress,
	Table,
	TableHead,
	TableCell,
	TableBody,
	TableRow,
	Button,
	Select,
	MenuItem,
	TextField,
	LinearProgress,
	NativeSelect
} from '@material-ui/core';
import axios from 'axios';
import uuid from 'uuid';
import AuthHelpers from '../../components/AuthHelperMethods';

class Step1 extends Component {
	state = {
		color: 'red',
		error: false,
		invMainCode: 'EAZ',
		invError: false
	};

	handleFileLoaded = (a, b) => {
		this.setState({ color: 'green' });
		console.log(a, b);
		if (a.length <= 1) {
			this.setState({ error: true });
		} else {
			this.props.onFileLoaded(a, b, this.state.invMainCode);
			this.props.next();
		}
	};

	handleError = (e, a) => {
		this.setState({ error: true });
		console.log(e, a);
	};
	handleInvChange = (e) => {
		const {value} = e.target;
		let error = false;
		if (value && value.length < 3) {
			error = true
		}
		this.setState({invMainCode: e.target.value.toUpperCase(), invError: error});
	}

	render() {
		return (
			<div>
				<TextField error={this.state.invError} value={this.state.invMainCode} name="inv-main-code" label="Invitation tracking" helperText="Tracking code for invitation code generated on import" style={{marginBottom: 10}} onChange={this.handleInvChange}/>
				<CSVReader
					cssClass="csv-reader-input"
					label="Select one CSV file   "
					onFileLoaded={this.handleFileLoaded}
					onError={this.handleError}
					inputId="ObiWan"
					inputStyle={{ color: this.state.color }}
				/>
				{this.state.error && <Typography>Error Loading file.</Typography>}
			</div>
		);
	}
}

const defaultMaps = [
	'None',
	'first_name',
	'last_name',
	'full_name',
	'email',
	'email2',
	'address1',
	'address2',
	'city',
	'state',
	'postal_code',
	'zip_code',
	'country',
	'phone',
	'GPA',
	'type',
	'perm_address1',
	'perm_address2',
	'perm_city',
	'perm_state',
	'perm_zip'
];

class Step2 extends Component {
	state = {
		loading: true,
		details: false,
		maps: []
	};

	componentDidMount() {
		this.handleLUIS();
	}

	waitFor = (ms) => new Promise((r) => setTimeout(r, ms));

	getMapFromLUIS = async (field) => {
		let res = await axios.post('https://us-central1-golden-key-227416.cloudfunctions.net/function-1', {
			terms: []
		});
		let { topScoringIntent } = await res.data;
		console.log(res.data.topScoringIntent);
		if (topScoringIntent.score < 0.6) {
			topScoringIntent.intent = 'None';
		}
		return Promise.resolve({ field, intent: topScoringIntent.intent });
	};

	async handleLUIS() {
		const { csvContent } = this.props;
		let fields = csvContent[0];
		fields = fields.map((v) => (v === '' ? 'None' : v));

		console.log(fields);

		// Disable LUIS
		// let res = await axios.post('https://bfxu6wqf4pfd7vykla2fobrsgq0dgvup.lambda-url.us-east-1.on.aws', {
		// 	terms: fields
		// });
		// let data = await res.data;

		let data = [];
		for (let i = 0; i < fields.length; i++) {
			data.push({ term: fields[i], value: null });
		}

		console.log('LUIS', data);
		const found = [];
		data = data.map((v) => {
			if (found.filter((f) => f === v.value).length > 0) {
				v.value = 'None';
			} else {
				found.push(v.value);
			}
			return v;
		});

		// const tempMaps = [];
		// for (let i = 0; i < fields.length; i++) {
		//     await this.getMapFromLUIS(fields[i]).then((field) => {
		//         tempMaps.push({ field: field.field, intent: field.intent });
		//     });
		// }

		this.setState({ loading: false, details: true, maps: data });
	}

	handlePrev = () => {
		this.props.prev();
	};

	handleNext = async () => {
		await this.props.update(this.state.maps);
		this.props.next();
	};

	getListForMaps = (index) => {
		const { maps } = this.state;
		const temp = maps.filter((v, i) => {
			return i === index;
		});
		console.log(index, temp);
		return temp.value;
	};

	handleSelectChange = (e) => {
		console.log(e.target);
		const { maps } = this.state;
		maps.forEach((v, i) => {
			if (v.term === e.target.name) {
				maps[i] = { term: v.term, value: e.target.value };
			}
		});
		this.setState({ maps });
	};

	render() {
		const { loading, details, maps } = this.state;
		const { csvContent } = this.props;
		const count = csvContent.length-1;
		console.log(maps);
		return (
			<div>
				<Typography color="primary" variant="subtitle1">
					We found {count} contacts to import
				</Typography>
				<Typography color="primary" variant="body2">
					This may vary upon real importing.
				</Typography>
				{loading && (
					<div>
						<br />
						<Typography variant="body2">Collecting information from CSV</Typography>
						<CircularProgress size={24} color="primary" />
					</div>
				)}
				{details && (
					<div>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Field</TableCell>
									<TableCell>Mapping</TableCell>
									<TableCell>Test</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{maps.map((f, i) => (
									<TableRow key={i}>
										<TableCell>{f.term}</TableCell>
										<TableCell>
											<Typography color={f.value === 'None' ? 'error' : 'primary'}>
												{f.value}
											</Typography>
										</TableCell>
										<TableCell>
											<Select
												value={f.value}
												onChange={this.handleSelectChange}
												displayEmpty
												name={f.term}
												style={{ fontSize: 12 }}
											>
												<MenuItem value="None">
													<em>None</em>
												</MenuItem>
												{defaultMaps.map((v, i) => (
													<MenuItem key={i} value={v}>
														{v}
													</MenuItem>
												))}
											</Select>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
						<div style={{ marginTop: 30 }}>
							<div>
								<Button onClick={this.handlePrev}>Back</Button>
								<Button variant="contained" color="primary" onClick={this.handleNext}>
									Next
								</Button>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}

class Step3 extends Component {
	state = {
		mapCountry: 'US',
		mapChapter: false
	};

	componentWillMount() {
		console.log('STEP3', this.props);
		let tmp = new Date();
		this.setState({ source: 'Grad', season: `SP${tmp.getYear() - 100}` });
		const auth = new AuthHelpers();
		const profile = auth.getProfile();
		console.log('My profile', profile);
		this.setState({
			mapCountry: profile.country || 'US',
			mapChapter: profile.chapter || false
		});
	}

	handleChange = (name) => (event) => {
		this.setState({ [name]: event.target.value });
	};

	handlePrev = () => {
		this.props.prev();
	};

	handleNext = async () => {
		await this.props.update(this.state);
		this.props.next();
	};

	render() {
		const { chapters, countries } = this.props;
		const seasons = [];
		for (let i = 16; i < 26; i++) {
			[ 'FL', 'SP' ].forEach((ele) => {
				seasons.push(`${ele}${i}`);
			});
		}

		return (
			<div>
				<Typography color="primary" variant="subtitle1">
					Additional mappings
				</Typography>
				<Typography color="error" variant="caption">
					Additional mappings will be applied to all imported contacts, and will override values from CSV if
					matched.
				</Typography>
				<Table style={{ width: 500, maxWidth: '50vw', marginBottom: 20 }}>
					<TableHead>
						<TableRow>
							<TableCell>Field</TableCell>
							<TableCell>Value</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell>Country</TableCell>
							<TableCell>
								<NativeSelect
									fullWidth
									value={this.state.mapCountry}
									onChange={this.handleChange('mapCountry')}
								>
									<option value={false}>- None -</option>
									{countries.map((v) => (
										<option key={v.id} value={v.id}>
											{v.name}
										</option>
									))}
								</NativeSelect>
							</TableCell>
						</TableRow>

						<TableRow>
							<TableCell>Chapter</TableCell>
							<TableCell>
								<NativeSelect
									fullWidth
									value={this.state.mapChapter}
									onChange={this.handleChange('mapChapter')}
								>
									{chapters.map((v) => (
										<option key={v.id} value={v.id}>
											{v.id} - {v.name}
										</option>
									))}
								</NativeSelect>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
				<Typography color="primary" variant="caption">
					Following are extra fields to identify Job Listing page of this system.
				</Typography>
				<Table style={{ width: 500, maxWidth: '50vw' }}>
					<TableHead>
						<TableRow>
							<TableCell>Field</TableCell>
							<TableCell>Value</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell width={30}>Source</TableCell>
							<TableCell>
								<NativeSelect
									fullWidth
									value={this.state.source}
									onChange={this.handleChange('source')}
								>
									{[ 'Call center', 'Grad', 'Undergrad' ].map((ele) => (
										<option value={ele}>{ele}</option>
									))}
								</NativeSelect>
							</TableCell>
						</TableRow>

						<TableRow>
							<TableCell>Season code</TableCell>
							<TableCell>
								<NativeSelect
									fullWidth
									value={this.state.season}
									onChange={this.handleChange('season')}
								>
									{seasons.map((ele) => <option value={ele}>{ele}</option>)}
								</NativeSelect>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
				<div style={{ marginTop: 30 }}>
					<div>
						<Button onClick={this.handlePrev}>Back</Button>
						<Button variant="contained" color="primary" onClick={this.handleNext}>
							Next
						</Button>
					</div>
				</div>
			</div>
		);
	}
}

class Step4 extends Component {
	componentWillMount() {
		console.log(this.props);
	}

	handlePrev = () => {
		this.props.prev();
	};

	handleNext = async () => {
		this.props.next();
	};

	render() {
		const { addMappings, mappings, csvContent } = this.props;

		const debate = mappings.filter((v, i) => v.value !== 'None');
		const debateCSV = csvContent.slice(1, Math.min(10, csvContent.length));

		return (
			<div>
				<Table>
					<TableHead>
						<TableRow>
							{debate.map((v, i) => (
								<TableCell key={i}>{v.value.replace(/(_|1)/g, ' ').toUpperCase()}</TableCell>
							))}
							{addMappings.mapCountry && <TableCell>COUNTRY</TableCell>}
							{addMappings.mapChapter && <TableCell>CHAPTER</TableCell>}
						</TableRow>
					</TableHead>
					<TableBody>
						{debateCSV.map((v, i) => (
							<TableRow key={i}>
								{v.map((v2, i2) => {
									if (mappings[i2].value === 'None') {
										return null;
									}

									return <TableCell key={i2}>{v2}</TableCell>;
								})}
								{addMappings.mapCountry && <TableCell>{addMappings.mapCountry}</TableCell>}
								{addMappings.mapChapter && <TableCell>{addMappings.mapChapter}</TableCell>}
							</TableRow>
						))}
					</TableBody>
				</Table>
				<div style={{ marginTop: 30 }}>
					<div>
						<Button onClick={this.handlePrev}>Back</Button>
						<Button variant="contained" color="primary" onClick={this.handleNext}>
							Next
						</Button>
					</div>
				</div>
			</div>
		);
	}
}

class Step5 extends Component {
	state = {
		uploading: false,
		completed: 0
	};

	componentWillMount() {
		console.log(this.props);
	}

	handlePrev = () => {
		this.props.prev();
	};

	handleNext = async () => {
		this.setState({ uploading: true });

		const { addMappings, mappings, csvContent, invMainCode } = this.props;
		const jobID = uuid.v4();
		var i,
			j,
			temparray,
			chunk = 250;
		for (i = 1, j = csvContent.length; i < j; i += chunk) {
			temparray = csvContent.slice(i, i + chunk);
			// do whatever
			var k,
				objects = [];
			for (k = 0; k < temparray.length; k++) {
				var p = temparray[k];
				let t = p.filter((ele, l) => mappings[l].value !== 'None');
				let r = {};
				mappings.forEach((ele, m) => {
					if (ele.value !== 'None') {
						Object.assign(r, { [ele.value]: p[m] });
					}
				});
				if (addMappings.mapCountry) {
					t.push(addMappings.mapCountry);
					Object.assign(r, { ccountry: addMappings.mapCountry });
				}
				if (addMappings.mapChapter) {
					t.push(addMappings.mapChapter);
					Object.assign(r, { ucode: addMappings.mapChapter });
				}
				r = {
					ccountry: r.ccountry,
					ucode: r.ucode,
					email: r.email,
					fname: r.first_name,
					mname: r.middle_name,
					lname: r.last_name,
					strategy: r.strategy,
					studentid: r.studentid,
					salutation: r.salutation,
					suffix: r.suffix,
					phone: r.phone,
					cstreet: r.address1,
					cstreet2: r.address2,
					ccity: r.city,
					cstateprov: r.state,
					cpostcode: r.zip_code,
					pstreet: r.perm_address1,
					pstreet2: r.perm_address2,
					pcity: r.perm_city,
					pstateprov: r.perm_state,
					ppostcode: r.perm_zip,
					pcountry: r.ccountry,
					email2: r.email2,
					eligibility_status: r.eligibility_status
				};

				//if (r.email === undefined) continue;

				objects.push(r);
			}
			console.log(mappings);
			console.table(objects);
			try {
				var tmpMaps = mappings.filter((v, i) => v.value !== 'None');
				tmpMaps = tmpMaps.map((v) => v.value);
				if (addMappings.mapCountry) {
					tmpMaps.push('country');
				}
				if (addMappings.mapChapter) {
					tmpMaps.push('chapter');
				}
				const res = await axios.post('/api/addUsers', {
					data: objects,
					mappings: tmpMaps,
					jobID,
					invMainCode
				});
				await res.data;
			} catch (e) {
				continue;
			}
			console.log(i, chunk, csvContent.length, (i + chunk) / csvContent.length * 100);
			await this.setState({ completed: Math.ceil((i + chunk) / csvContent.length * 100) });
		}

		await axios.post('/api/jobs', {
			data: {
				jobID,
				owner: this.props.confirm.email,
				created: new Date(),
				chapter: addMappings.mapChapter,
				source: addMappings.source,
				season: addMappings.season
			}
		});

		this.setState({ uploading: false });

		this.props.finish(jobID);
	};

	render() {
		const { csvContent } = this.props;

		return (
			<div>
				<Typography color="primary" variant="subtitle1">
					Will upload {csvContent.length - 1} contacts
				</Typography>

				<div style={{ marginTop: 30 }}>
					<div>
						<Button onClick={this.handlePrev}>Back</Button>
						<Button
							variant="contained"
							color="secondary"
							disabled={this.state.uploading}
							onClick={this.handleNext}
						>
							Upload
						</Button>
					</div>
				</div>
				<div style={{ marginTop: 30 }}>
					<div style={{ width: 500, maxWidth: '50vw' }}>
						<LinearProgress variant="determinate" value={this.state.completed} />
					</div>
				</div>
			</div>
		);
	}
}

class Upload extends Component {
	state = {
		step: 0,
		csvContent: [],
		mappings: [],
		addMappings: [],
		fileName: '',
		finished: false,
		chapters: [],
		countries: [],
		invMainCode: "EAZ"
	};

	componentWillMount() {
		this.getChapters();
	}

	getChapters() {
		axios.get('/api/chapters').then((res) => {
			console.log(res.data);
			this.setState({ chapters: res.data });
		});
		axios.get('/api/countries').then((res) => {
			console.log(res.data);
			this.setState({ countries: res.data });
		});
	}

	getStep(index) {
		switch (index) {
			case 4:
				return (
					<Step5
						{...this.state}
						prev={this.handlePrev}
						finish={this.uploadAndFinish}
						confirm={this.props.confirm}
					/>
				);
			case 3:
				return (
					<Step4 next={this.handleNext} update={this.updateAddMaps} prev={this.handlePrev} {...this.state} />
				);
			case 2:
				return (
					<Step3 next={this.handleNext} update={this.updateAddMaps} prev={this.handlePrev} {...this.state} />
				);
			case 1:
				return (
					<Step2
						activeStep={this.state.step}
						update={this.updateMaps}
						next={this.handleNext}
						prev={this.handlePrev}
						{...this.state}
					/>
				);
			default:
				return <Step1 activeStep={this.state.step} next={this.handleNext} onFileLoaded={this.onFileLoaded} />;
		}
	}

	onFileLoaded = (csvContent, fileName, invMainCode) => {
		this.setState({
			csvContent,
			fileName,
			invMainCode
		});
	};

	getSteps = () => [ 'Upload a CSV', 'Configure base mappings', 'Additional mappings', 'Preview contacts', 'Finish' ];

	updateMaps = (mappings) => {
		this.setState({ mappings });
	};

	updateAddMaps = (addMappings) => {
		this.setState({ addMappings });
	};

	handleNext = () => {
		this.setState((state) => ({
			step: state.step + 1
		}));
	};

	handlePrev = () => {
		this.setState((state) => ({
			step: state.step - 1
		}));
	};

	handleReset = () => {
		this.setState({
			activeStep: 0
		});
	};

	uploadAndFinish = (jobID) => {
		this.setState({
			finished: true,
			step: 0,
			csvContent: [],
			mappings: [],
			addMappings: [],
			fileName: '',
			jobID
		});

		setTimeout(() => {
			this.setState({ finish: false });
		}, 2000);
	};

	render() {
		const steps = this.getSteps();
		const { step, finished } = this.state;

		return (
			<Drawer auth={this.props.auth}>
				<h1>Upload</h1>
				{finished && (
					<Paper style={{ padding: 30, marginBottom: 20 }}>
						<Typography style={{ color: '#22700d' }} variant="button">
							Upload finished
						</Typography>
						<Button
							onClick={() => {
								this.props.history.push(`/job/${this.state.jobID}`);
							}}
						>
							Click here for job details
						</Button>
					</Paper>
				)}
				<Paper className="">
					<Button
						color="primary"
						style={{ margin: 20 }}
						onClick={() => {
							this.setState({
								finished: false,
								step: 0,
								csvContent: [],
								mappings: [],
								addMappings: [],
								fileName: ''
							});
						}}
					>
						Start over
					</Button>
					<Stepper orientation="vertical" activeStep={step}>
						{steps.map((label, index) => (
							<Step key={label}>
								<StepLabel>{label}</StepLabel>
								<StepContent>{this.getStep(index)}</StepContent>
							</Step>
						))}
					</Stepper>
				</Paper>
			</Drawer>
		);
	}
}

export default Upload;
