import React, { Component } from "react";
import { Card, Grid, TextField, Button } from '@material-ui/core';
import AuthHelperMethods from './components/AuthHelperMethods';

/* We want to import our 'AuthHelperMethods' component in order to send a login request */

import './styles/login.css';


class Login extends Component {

    /* In order to utilize our authentication methods within the AuthService class, we want to instantiate a new object */

    state = {
        username: "",
        password: ""
    }


    /* Fired off every time the use enters something into the input fields */
    _handleChange = (e) => {
        this.setState(
            {
                [e.target.name]: e.target.value
            }
        )
    }

    handleFormSubmit = (e) => {
        e.preventDefault();
        console.log("Submitted")
        const Auth = new AuthHelperMethods();
        Auth.login(this.state.username, this.state.password).then(res => {
            this.props.history.replace('/');
        });
        /* Here is where all the login logic will go. Upon clicking the login button, we would like to utilize a login method that will send our entered credentials over to the server for verification. Once verified, it should store your token and send you to the protected route. */
    }

    handleUsernameChange = (e) => {
        this.setState({
            username: e.target.value
        })
    }

    handlePasswordChange = (e) => {
        this.setState({
            password: e.target.value
        })
    }

    componentWillMount() {

        /* Here is a great place to redirect someone who is already logged in to the protected route */

    }

    render() {
        return (
            <React.Fragment>
                <Grid className="main-wrapper" container justify="center">
                    <Grid item xs={3}>

                        <Card justify="center">
                            <Grid container justify="center" className="logo">
                                <img src="http://35.211.49.207/wp-content/uploads/2018/12/logo.png" alt="Logo" />
                            </Grid>

                            <form className="form-container" onSubmit={this.handleFormSubmit}>
                                <h3>Login</h3>
                                <TextField
                                    id="standard-username-input"
                                    label="Email"
                                    type="text"
                                    autoComplete="current-username"
                                    margin="normal"
                                    onChange={this.handleUsernameChange}
                                />
                                <TextField
                                    id="standard-password-input"
                                    label="Password"

                                    type="password"
                                    autoComplete="current-password"
                                    margin="normal"
                                    onChange={this.handlePasswordChange}
                                />
                                <Button variant="contained" color="primary" type="submit" className="submit" onClick={this.handleFormSubmit}>
                                    Login
                                </Button>
                            </form>
                        </Card>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }

}

export default Login;