import React, { Component } from 'react';
import Drawer from '../../components/drawer';
import { Grid, CircularProgress, Button, Typography } from '@material-ui/core';
import Axios from 'axios';

export default class SyncHubspot extends Component {

    constructor() {
        super()
        this.state = {
            loading: false,
            finished: false,
            response: []
        }
    }

    syncToHubspot = async () => {
        this.setState({ response: [], loading: true, finished: false });
        const res = await Axios.post('/api/sync-to-hubspot', {});
        this.setState({ loading: false, finished: true });
        const data = await res.data;
        console.log(data);
        if (data.synced_invitations) {
            this.setState({ response: data.synced_invitations });
        }
    }

    render() {
        const { loading, finished } = this.state;
        return (
            <Grid container className="backgrounded">
                <Drawer auth={this.props.auth}>
                    <Grid container md={12}>
                        <Grid item md={12} alignItems="center" justify="center">
                            <Button disabled={loading} color="primary" variant="raised" onClick={this.syncToHubspot} style={{ marginBottom: 20, marginRight: 10 }}>Sync Invitations to Hubspot</Button>
                            {loading &&
                                <CircularProgress />
                            }
                            {finished &&
                                <pre style={{ marginBottom: 20 }}>
                                    Invitations Synced:
                                    {this.state.response &&
                                        <ul>
                                            {this.state.response.map((invitation) => {
                                                return (<li>{invitation}</li>)
                                            })}
                                        </ul>
                                    }
                                </pre>
                            }
                        </Grid>

                    </Grid>
                </Drawer>
            </Grid>
        )
    }
}
