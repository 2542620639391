import React, { Component } from 'react';
import Drawer from '../../components/drawer';
import {
	Grid,
	CircularProgress,
	Button
} from '@material-ui/core';
import Axios from 'axios';
import MUIDataTable from 'mui-datatables';

export default class JobDetails extends Component {
	state = {
		jobs: [],
		loading: false
	};

	componentDidMount() {
		this.getJobs();
	}

	async getJobs() {
		this.setState({ loading: true });
		const { match: { params } } = this.props;
		const res = await Axios.get(`/api/jobs/${params.jobid}`);
		var jobs = await res.data;
		jobs = jobs.map((v) => {
			delete v.__v;
			delete v._id;
			delete v.jobID;
			return v;
		});
		this.setState({ jobs, loading: false });
	}

	render() {
		const { jobs, loading } = this.state;
		let columns = [
			'ucode',
			'strategy',
			'studentid',
			'salutation',
			'suffix',
			'phone',
			'fname',
			'mname',
			'lname',
			'cstreet',
			'cstreet2',
			'ccity',
			'cstateprov',
			'cpostcode',
			'ccountry',
			'pstreet',
			'pstreet2',
			'pcity',
			'pstateprov',
			'ppostcode',
			'pcountry',
			'email',
			'email2'
		];

		let standard = [
			'ucode',
			'fname',
			'lname',
			'cstreet',
			'ccity',
			'cstateprov',
			'cpostcode',
			'ccountry',
			'email'
		];

		let filters = [ 'ccity', 'cstateprov' ];

		columns = columns.map((ele) => {
			let display = false;
			let filter = false;
			if (standard.indexOf(ele) >= 0) {
				display = true;
			}

			if (filters.indexOf(ele) >= 0) {
				filter = true;
			}

			return {
				name: ele,
				label: ele,
				options: {
					display,
					filter
				}
			};
		});

		const options = {
			filterType: 'dropdown',
			selectableRows: false,
			print: false,
			download: false,
			fixedHeader: true,
			rowsPerPage: 15,
			rowsPerPageOptions: [ 10, 15, 20, 50, 100 ],
			responsive: 'stacked'
		};
		return (
			<Grid container className="backgrounded">
				<Drawer auth={this.props.auth}>
					<Grid container md={12}>
						<Grid item md={6}>
							{loading && <CircularProgress />}
						</Grid>
						<Grid item md={6} alignItems="flex-end" justify="flex-end">
							<Button
								disabled={loading}
								color="primary"
								variant="raised"
								onClick={this.saveAsXSLX}
								style={{ marginBottom: 20, float: 'right' }}
							>
								Export to XLSX
							</Button>
							<Button
								disabled={loading}
								color="primary"
								variant="raised"
								onClick={this.wordpress}
								style={{ marginBottom: 20, marginRight: 10, float: 'right' }}
							>
								Export to Wordpress
							</Button>
						</Grid>
						<Grid item md={12}>
							<MUIDataTable title={'Contact List'} data={jobs} columns={columns} options={options} />
						</Grid>
					</Grid>
				</Drawer>
			</Grid>
		);
	}

	saveAsXSLX = async () => {
		const { match: { params } } = this.props;
		this.props.history.push(`/job/${params.jobid}/export`);
	};

	wordpress = () => {
		const { match: { params } } = this.props;
		this.props.history.push(`/wordpress/${params.jobid}`);
	};
}
