import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Error from '@material-ui/icons/Error';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import {
	Home as HomeIcon,
	Launch as LaunchIcon,
	Backup,
	AssignmentTurnedIn,
	People,
	QuestionAnswer,
	OfflinePin,
	PlayForWork,
	RoundedCorner,
	Subtitles,
	Face,
	SupervisedUserCircle,
	HowToVote,
	AddBox,
	Toc
} from '@material-ui/icons';

const drawerWidth = 240;

const menu = [
	{
		title: 'Jobs',
		path: '/',
		icon: <AssignmentTurnedIn />
	},
	{
		title: 'New upload',
		path: '/upload',
		icon: <Backup />
	}
];

const menu2 = [
	// {
	// 	title: 'Settings',
	// 	path: '/settings',
	// 	icon: <Settings />
	// },
	{
		title: 'Users',
		path: '/users',
		icon: <People />
	},
	{
		title: 'Chapters',
		path: '/chapters',
		icon: <Subtitles />
	},
	{
		title: 'Students',
		path: '/students',
		icon: <Face />
	}
];

const menu3 = [
	{
		title: 'Eligibility submits',
		path: '/eligibility',
		icon: <QuestionAnswer />
	},
	{
		title: 'Approved prospects',
		path: '/approved',
		icon: <OfflinePin />
	},
	{
		title: 'Rejected prospects',
		path: '/rejected',
		icon: <Error />
	},
	{
		title: 'Offline invitations',
		path: '/offline/listing',
		icon: <PlayForWork />
	},
	{
		title: 'Generate offline invitations',
		path: '/offline/generate',
		icon: <RoundedCorner />
	},
	{
		title: 'Chapter invitations',
		path: '/chapter-invitations/listing',
		icon: <Toc />
	},
	{
		title: 'Generate chapter invitations',
		path: '/chapter-invitations/generate',
		icon: <AddBox />
	},
	{
		title: 'Sync invitations to Hubspot',
		path: '/sync-to-hubspot',
		icon: <Backup />
	},
	{
		title: 'Count Hubspot votes',
		path: '/count-hubspot-votes',
		icon: <HowToVote />
	},
	{
		title: 'Change Users Roles',
		path: '/change-users-roles',
		icon: <SupervisedUserCircle />
	}
];

const menu4 = [
	{
		title: 'Logout',
		path: '/logout',
		icon: <LaunchIcon />
	}
];

const styles = (theme) => ({
	root: {
		display: 'flex',
		flexGrow: 1
	},
	drawer: {
		[theme.breakpoints.up('sm')]: {
			width: drawerWidth,
			flexShrink: 0
		}
	},
	appBar: {
		marginLeft: drawerWidth,
		[theme.breakpoints.up('sm')]: {
			width: `calc(100% - ${drawerWidth}px)`
		}
	},
	menuButton: {
		marginRight: 20,
		[theme.breakpoints.up('sm')]: {
			display: 'none'
		}
	},
	toolbar: { ...theme.mixins.toolbar, justifyContent: 'center', alignItems: 'center', display: 'flex' },
	drawerPaper: {
		width: drawerWidth
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing.unit * 3
	}
});

class ResponsiveDrawer extends React.Component {
	state = {
		mobileOpen: false
	};

	handleDrawerToggle = () => {
		this.setState((state) => ({ mobileOpen: !state.mobileOpen }));
	};

	render() {
		const { classes, theme } = this.props;

		const drawer = (
			<div>
				<div className={classes.toolbar}>
					<Typography align="center">Welcome {this.props.auth.getProfile().email}</Typography>
				</div>
				<Divider />
				<List>
					{menu.map((item) => (
						<Link to={item.path} key={item.title}>
							<ListItem button>
								<ListItemIcon>{item.icon}</ListItemIcon>
								<ListItemText style={{ whiteSpace: 'pre-wrap' }} primary={item.title} />
							</ListItem>
						</Link>
					))}
					<Divider />
					{menu2.map((item) => (
						<Link to={item.path} key={item.title}>
							<ListItem button>
								<ListItemIcon>{item.icon}</ListItemIcon>
								<ListItemText style={{ whiteSpace: 'pre-wrap' }} primary={item.title} />
							</ListItem>
						</Link>
					))}
					<Divider />
					{menu3.map((item) => (
						<Link to={item.path} key={item.title}>
							<ListItem button>
								<ListItemIcon>{item.icon}</ListItemIcon>
								<ListItemText style={{ whiteSpace: 'pre-wrap' }} primary={item.title} />
							</ListItem>
						</Link>
					))}
					<Divider />
					{menu4.map((item) => (
						<Link to={item.path} key={item.title}>
							<ListItem button>
								<ListItemIcon>{item.icon}</ListItemIcon>
								<ListItemText style={{ whiteSpace: 'pre-wrap' }} primary={item.title} />
							</ListItem>
						</Link>
					))}
					<Divider />
				</List>
			</div>
		);

		return (
			<div className={classes.root}>
				<CssBaseline />
				<AppBar position="fixed" className={classes.appBar}>
					<Toolbar>
						<IconButton
							color="inherit"
							aria-label="Open drawer"
							onClick={this.handleDrawerToggle}
							className={classes.menuButton}
						>
							<MenuIcon />
						</IconButton>
						<Typography variant="h6" color="inherit" noWrap>
							GoldenKey
						</Typography>
					</Toolbar>
				</AppBar>
				<nav className={classes.drawer}>
					<Hidden smUp implementation="css">
						<Drawer  auth={this.props.auth}
							container={this.props.container}
							variant="temporary"
							anchor={theme.direction === 'rtl' ? 'right' : 'left'}
							open={this.state.mobileOpen}
							onClose={this.handleDrawerToggle}
							classes={{
								paper: classes.drawerPaper
							}}
						>
							{drawer}
						</Drawer>
					</Hidden>
					<Hidden xsDown implementation="css">
						<Drawer  auth={this.props.auth}
							classes={{
								paper: classes.drawerPaper
							}}
							variant="permanent"
							open
						>
							{drawer}
						</Drawer>
					</Hidden>
				</nav>
				<main className={classes.content}>
					<div className={classes.toolbar} />
					{this.props.children}
				</main>
			</div>
		);
	}
}

ResponsiveDrawer.propTypes = {
	classes: PropTypes.object.isRequired,
	// Injected by the documentation to work in an iframe.
	// You won't need it on your project.
	container: PropTypes.object,
	theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(ResponsiveDrawer);
