import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import withAuth from './components/withAuth';

import Login from './login';
import NoMatch from './custom/404';
import Logout from './custom/logout';
import Upload from './core/upload';
import Jobs from './core/jobs';
import JobDetails from './core/jobs/details';
import Wordpress from './core/jobs/wordpress';
import Elg from './core/jobs/elg';
import Users from './core/users';
import Settings from './core/settings';
import Approved from './core/jobs/approved';
import Rejected from './core/jobs/rejected';
import Offline from './core/jobs/offline';
import OfflineListing from './core/jobs/offlisting';
import Exporter from './core/jobs/export';
import Chapters from './core/settings/chapters';
import ChapterAdd from './core/settings/chapters_add';
import ChapterEdit from './core/settings/chapters_add';
import Students from './core/students';
import SyncHubspot from "./core/jobs/SyncHubspot";
import CountHubspotVotes from "./core/jobs/CountHubspotVotes";
import ChangeUsersRoles from "./core/roles/ChangeUsersRoles";
import ChapterInvitations from './core/jobs/ChapterInvitations';
import ChapterInvitationsListing from './core/jobs/ChapterInvitationsListing';

class App extends Component {
	render() {
		return (
			<div className="backgrounded">
				<Router>
					<Switch>
						<Route path="/" exact component={withAuth(Jobs)} />
						<Route path="/login" exact component={Login} />
						<Route path="/logout" exact component={withAuth(Logout)} />
						<Route path="/upload" component={withAuth(Upload)} />
						<Route path="/job/:jobid/export" component={withAuth(Exporter)} />
						<Route path="/job/:jobid" component={withAuth(JobDetails)} />
						<Route path="/jobs" component={withAuth(Jobs)} />
						<Route path="/wordpress/:id" component={withAuth(Wordpress)} />
						<Route path="/eligibility" component={withAuth(Elg)} />
						<Route path="/approved" component={withAuth(Approved)} />
						<Route path="/rejected" component={withAuth(Rejected)} />
						<Route path="/offline/generate" component={withAuth(Offline)} />
						<Route path="/offline/listing" component={withAuth(OfflineListing)} />
						<Route path="/chapter-invitations/generate" component={withAuth(ChapterInvitations)} />
						<Route path="/chapter-invitations/listing" component={withAuth(ChapterInvitationsListing)} />
						<Route path="/user/:id" component={withAuth(Users)} />
						<Route path="/users" component={withAuth(Users)} />
						<Route path="/settings" component={withAuth(Settings)} />
						<Route path="/chapters/add" component={withAuth(ChapterAdd)} />
						<Route path="/chapters/:id/edit" component={withAuth(ChapterEdit)} />
						<Route path="/chapters" component={withAuth(Chapters)} />
						<Route path="/students" component={withAuth(Students)} />
						<Route path="/sync-to-hubspot" component={withAuth(SyncHubspot)} />
						<Route path="/count-hubspot-votes" component={withAuth(CountHubspotVotes)} />
						<Route path="/change-users-roles" component={withAuth(ChangeUsersRoles)} />
						<Route component={withAuth(NoMatch)} />
					</Switch>
				</Router>
			</div>
		);
	}
}

export default App;
