import React from 'react'
import { Typography } from '@material-ui/core';
import Drawer from '../components/drawer';

export default class NoMatch extends React.Component {
    render() {
        return (
            <Drawer auth={this.props.auth}>
                <h3>Nothing here!</h3>
            </Drawer>
        )
    }
}