import React, { Component } from 'react';
import Drawer from '../../components/drawer';

import moment from 'moment';
import { Grid, CircularProgress } from '@material-ui/core';
import Axios from 'axios';
import MUIDataTable from 'mui-datatables';

var searchTimer;

export default class ChapterInvitationsListing extends Component {
    state = {
        users: [],
        loading: false,
        showDialog: false,
        done: false,
        total: 0,
        page: 0,
        search: null
    };

    componentDidMount() {
        this.getJobs();
    }

    async getJobs() {
        this.setState({ loading: true });
        const { match: { params } } = this.props;
        const { page, search } = this.state;
        const res = await Axios.post(`/api/orm/chapter-invitations/listing`,
            {
                page,
                pageSize: 15,
                search
            }
        );
        var invitations = await res.data.response;
        let invitationsFormatted = [];
        var total = await res.data.count;
        invitations.forEach((ele) => {
            ele.expiration_date = moment(ele.expiration_date).format('YYYY-MM-DD HH:mm:ss');
            ele.created_at = moment(ele.created_at).format('YYYY-MM-DD HH:mm:ss');
            invitationsFormatted.push(ele);
        });

        this.setState({
            total,
            users: invitationsFormatted,
            loading: false,
        });
    }

    handleDone = () => {
        this.setState({ showDialog: false, done: false });
    };

    render() {
        const { users, done } = this.state;

        const options = {
            filterType: 'dropdown',
            selectableRows: false,
            selectableRowsOnClick: false,
            print: true,
            searchText: this.state.search,
            download: true,
            fixedHeader: true,
            rowsPerPage: 15,
            serverSide: true,
            count: this.state.total,
            rowsPerPageOptions: [10, 15, 20, 50, 100],
            responsive: 'stacked',
            onTableChange: (action, tableState) => {
                console.log(action, tableState);

                if (action == 'changePage') {
                    this.setState({ page: tableState.page }, () => {
                        this.getJobs();
                    })
                } else if (action == 'changeRowsPerPage') {
                    this.setState({ pageSize: tableState.rowsPerPage }, () => {
                        this.getJobs()
                    })
                } else if (action == 'search') {
                    clearTimeout(searchTimer);
                    searchTimer = setTimeout(() => {
                        console.log(`I should search for ${tableState.searchText}`)
                        this.setState({ search: tableState.searchText }, () => {
                            this.getJobs();
                        })
                    }, 600);
                }
            }
        };

        const columns = ['invcode', 'expiration_date', 'uses_count', 'created_at'];

        return (
            <Grid container className="backgrounded">
                <Drawer auth={this.props.auth}>
                    <Grid container>
                        <Grid item md={12}>
                            {this.state.loading &&
                                <CircularProgress />
                            }
                            <MUIDataTable
                                title={'Chapter invitations List'}
                                data={users}
                                columns={columns}
                                options={options}
                            />
                        </Grid>
                    </Grid>
                </Drawer>
            </Grid>
        );
    }
}
