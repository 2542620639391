import React from 'react'
import MomentInteral from 'react-moment';

export default class Moment extends React.Component {
    render() {
        const calendarStrings = {
            lastDay: '[Yesterday at] LT',
            sameDay: '[Today at] LT',
            nextDay: '[Tomorrow at] LT',
            lastWeek: '[last] dddd [at] LT',
            nextWeek: 'dddd [at] LT',
            sameElse: 'L LT'
        };
        return (
            <MomentInteral calendar={calendarStrings}>
                {this.props.children}
            </MomentInteral>
        )
    }
}