import React, { Component } from 'react';
import Drawer from '../../components/drawer';
import axios from 'axios';
import {
	Grid,
	Button,
	TableCell,
	TableRow,
	FormControl,
	TextField,
	FormLabel,
	Switch,
	Typography
} from '@material-ui/core';
import { PersonAdd, Label } from '@material-ui/icons';
import Axios from 'axios';
import MUIDataTable from 'mui-datatables';
import withAuth from '../../components/withAuth';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Moment from '../../components/Moment';

const protectedUsers = {
	"tony.baqain@trueinteraction.com": 1,
	"jsamuel@trueinteraction.com": 1
}

class Users extends Component {
	state = {
		users: [{email: ''}],
		open: false,
		resetOpen: false,
		newuser: {
			email: "",
			error: false,
			password: "",
			confirm_password:""
		},
		resetPassword: {
			password: "",
			confirm_password: "",
			error: false,
			email: "tony.baqain@trueinteraction.com"
		}
	};

	componentWillMount() {
		this.getUsers();
	}
	getUsers = () => {
		Axios.get('/api/users').then((res) => {
			this.setState({ users: res.data });
		});
	};
		
	render() {
		const { users } = this.state;

		const columns = [ 'email', 'name', 'privileged', 'enabled'];
		const that = this;
		const expandable = protectedUsers.hasOwnProperty(this.props.auth.getProfile().email.toLowerCase());
		const options = {
			sort: true,
			search: true,
			print: false,
			columns: false,
			download: false,
			selectableRows: 'none',
			expandableRows: expandable,
			filter: true,
			filterType: 'dropdown',
			responsive: 'stacked',
			isRowSelectable: (index) => this.props.confirm.hasOwnProperty('email') && users[index] !== this.props.confirm,
			renderExpandableRow: (data, meta) => {
				console.log(meta);
				const disabled = !that.actionAllowed(data[0])

				return (
					<TableRow>
						<TableCell colSpan={4}>
							<Grid
								container
								direction="row"
								spacing={40}
								justify="flex-start"
								alignItems="flex-start"
								style={{ margin: 10, paddingLeft: 20, paddingRight: 20 }}
							>
								
									<Grid md={6} item>
										<Typography variant="body1" style={{ marginBottom: 10 }}>
											Operations:
										</Typography>
										<Button
											color="primary"
											variant="contained"
											disabled={disabled}
											onClick={() => {
												const tmp = this.state.resetPassword;
												tmp.email = data[0];
												that.setState({resetPassword: tmp}, () => {
													this.setState({resetOpen: true})
												})
											}}
										>
											Reset password
										</Button>
										<br/>
										<Button
											style={{ marginTop: 10 }}
											color="secondary"
											variant="contained"
											disabled={disabled}
											onClick={() => {
												that.handleDelete({email: data[0]});
											}}
										>
											Permanent delete
										</Button>
									</Grid>
								
							</Grid>
						</TableCell>
					</TableRow>
				);
			}
		};

		
		return (
			<Drawer auth={this.props.auth}>
				<Grid container justify="flex-end" alignItems="center">
					<Grid item>
						{protectedUsers.hasOwnProperty(this.props.auth.getProfile().email.toLowerCase()) &&
							<Button
								color="primary"
								variant="contained"
								size="small"
								onClick={() => this.setState({ open: true })}
							>
								<PersonAdd style={{ marginRight: 5 }} />
								Add User
							</Button>
						}
					</Grid>
				</Grid>
				<Grid container justify="flex-start" alignItems="center" style={{ marginTop: 10 }}>
					<Grid item md={12}>
						<MUIDataTable title="Users" options={options} columns={columns} data={users} />
					</Grid>
				</Grid>
				<Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
					<DialogTitle id="form-dialog-title">New User</DialogTitle>
					<DialogContent>
						<TextField autoFocus margin="dense" id="email" label="Email Address" type="email" fullWidth onChange={(v) => {
							const tmp = this.state.newuser;
							tmp.email = v.target.value;
							this.setState({newuser: tmp});
						}}/>
						<TextField error={this.state.newuser.error.length} margin="dense" id="password" label="Password" type="password" fullWidth onChange={(v) => {
							const tmp = this.state.newuser;
							tmp.password = v.target.value;
							this.setState({newuser: tmp});
						}}/>
						<TextField error={this.state.newuser.error.length} margin="dense" id="confirm_password" label="Confirm Password" type="password" fullWidth onChange={(v) => {
							const tmp = this.state.newuser;
							tmp.confirm_password = v.target.value;
							this.setState({newuser: tmp});
						}}/>
						{this.state.newuser.error && 
							<Typography variant="body1" color="secondary" style={{textAlign: 'left', marginTop: 10}}>
								{this.state.newuser.error}
							</Typography>
						}
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleClose} color="primary">
							Cancel
						</Button>
						<Button onClick={this.handleNewUser} color="primary">
							Add User
						</Button>
					</DialogActions>
				</Dialog>

				<Dialog open={this.state.resetOpen} onClose={this.handleClose} aria-labelledby="form-dialog-title">
					<DialogTitle id="form-dialog-title">Reset password</DialogTitle>
					<DialogContent>
						<TextField disabled margin="dense" id="email" label="Email Address" type="email" fullWidth value={this.state.resetPassword.email}/>
						<TextField error={this.state.resetPassword.error !== false} margin="dense" id="password" label="Password" type="password" fullWidth onChange={(v) => {
							const tmp = this.state.resetPassword;
							tmp.password = v.target.value;
							this.setState({resetPassword: tmp});
						}}/>
						<TextField error={this.state.resetPassword.error !== false} margin="dense" id="confirm_password" label="Confirm Password" type="password" fullWidth onChange={(v) => {
							const tmp = this.state.resetPassword;
							tmp.confirm_password = v.target.value;
							this.setState({resetPassword: tmp});
						}}/>
						{this.state.resetPassword.error && 
							<Typography variant="body1" color="secondary" style={{textAlign: 'left', marginTop: 10}}>
								{this.state.resetPassword.error}
							</Typography>
						}
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleClose} color="primary">
							Cancel
						</Button>
						<Button onClick={this.handleResetPassword} color="primary">
							Reset password
						</Button>
					</DialogActions>
				</Dialog>
			</Drawer>
		);
	}

	handleNewUser = () => {
		const {newuser} = this.state;
		if (newuser.password.length < 6) {
			this.setState({newuser: {...newuser,...{error: "Password should be at least 6 characters."}}});
			return;
		}
		if (newuser.password !== newuser.confirm_password) {
			this.setState({newuser: {...newuser,...{error: "Passwords do not match."}}});
			return;
		}
		Axios.post("/api/users", {data: newuser})
		.then(res => {
			const {data} = res;
			console.log(data);
			this.hanldeRefresh()
		}).catch((reason) => {
			console.log(reason);
			const tmp = this.state.newuser;
			tmp.error = "User already exists";
			this.setState({newuser: tmp})
		})
	}

	actionAllowed = (target) => {
		const me = this.props.auth.getProfile().email;
		console.log(me, target)
		if (protectedUsers.hasOwnProperty(target.toLowerCase())) {
			if (protectedUsers.hasOwnProperty(me.toLowerCase())) {
				return true;
			}
			return false;
		} else if (protectedUsers.hasOwnProperty(me.toLowerCase())) {
			return true;
		} else {
			if (me.toLowerCase() === target.toLowerCase()) {
				return true;
			}
		}

		return false;
	}

	handleResetPassword = () => {
		// console.log("PROFILE", this.props.auth.getProfile().email)
		const {resetPassword} = this.state;
		if (!this.actionAllowed(resetPassword.email)) {
			this.setState({resetPassword: {...resetPassword,...{error: `${resetPassword.email} is a protected user, only users with privileges is allowed to do this action.`}}});
			return;
		}
		
		resetPassword.error = false;
		this.setState({resetPassword})
		console.log(resetPassword)
		if (resetPassword.password.length < 6) {
			this.setState({resetPassword: {...resetPassword,...{error: "Password should be at least 6 characters."}}});
			return;
		}
		if (resetPassword.password !== resetPassword.confirm_password) {
			this.setState({resetPassword: {...resetPassword,...{error: "Passwords do not match."}}});
			return;
		}
		Axios.patch("/api/users", {data: resetPassword})
		.then(res => {
			const {data} = res;
			console.log(data);
			this.hanldeRefresh()
		}).catch((reason) => {
			console.log(reason);
			const tmp = this.state.newuser;
			tmp.error = "Error appeared, please try later.";
			this.setState({newuser: tmp})
		})
	}

	handleDelete = (user) => {
		Axios.delete("/api/users", {data: user}).then((res) => {
			console.log(res);
			this.hanldeRefresh();
		});
	}

	hanldeRefresh = () => {
		this.getUsers();
		this.handleClose();
	}

	handleClose = () => {
		this.setState({ newuser: {
			email: "",
			error: false,
			password: "",
			confirm_password:""
		},
		resetPassword: {
			password: "",
			confirm_password: "",
			error: false,
			email: "tony.baqain@trueinteraction.com"
		},
		open: false, resetOpen: false });
	};

	hanldeOpen = () => {
		this.setState({ open: true });
	};
}

export default withAuth(Users);
