/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import Drawer from '../../components/drawer';
import { Grid, Button, IconButton, Dialog, DialogTitle, DialogContent, TextField, DialogActions, DialogContentText, Select, MenuItem, FormControl, FormLabel, Typography } from '@material-ui/core';
import Axios from 'axios';
import MUIDataTable from 'mui-datatables';
import Moment from 'react-moment';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ErrorIcon from "@material-ui/icons/Error";
import DeleteIcon from "@material-ui/icons/Delete";

export default class Elg extends Component {
	state = {
		users: [],
		loading: false,
		approving: false,
		showDialog: false,
		done: false,
		currentApproval: false,
		chapters: [],
		selectOpen: false,
		deleteDialog: false,
    deletion: null,
		domains: [
			{
				name: "GoldenKey main property (goldenkey.org)",
				url: "https://www.goldenkey.org"
			},
			{
				name: "Malaysia domain (.MY)",
				url: "http://malaysia.goldenkey.org/"
			},
			{
				name: "South Africa (.ZA)",
				url: "https://yes.joingoldenkey.org.za"
			},
			{
				name: "Staging",
				url: "https://gk-stage.trueinteraction.com"
			},
			{
				name: "Preprod",
				url: "https://gk-preprod.trueinteraction.com"
			}
		]
	};

	constructor() {
		super();
		this.state = this.state;
	}

	componentDidMount() {
		this.getJobs();
	}

	async getJobs() {
		this.setState({ loading: true });
		const { match: { params } } = this.props;
		const res = await Axios.get(`/api/orm/users`);
		let users = await res.data;
		const chaptersRes = await Axios.get('/api/chapters');
		let chapters = await chaptersRes.data;
		users = users.map((ele) => {
			return { ...ele, ...{ status: ele.status === '0' ? 'Pending' : 'Approved' }, ...{ pdf: ele.hasOwnProperty('image') ? ele.image.guid : 'N/A' } };
		});
		this.setState({ users, loading: false, page: 0, count: res.data.length, chapters });
	}

	handleClose = () => {
    this.setState({
      showDialog: false,
      currentApproval: false,
      deleteDialog: false,
      deletion: null,
    });
  };

	handleDelete = async (id) => {
    const deletion = await Axios.delete("/api/orm/users/" + id);

    const successState = {
      deletion: null,
      deleteDialog: false,
      showDialog: false,
      users: this.state.users.filter((user) => user.id !== id),
    };
    const errorState = { deletion: null, deleteDialog: false };

    this.setState(
      {
        deletion:
          deletion.status === 200
            ? "confirmed"
            : deletion.status === 400
            ? "not-found"
            : "error",
      },
      () => {
        setTimeout(() => {
          this.setState(deletion.status === 200 ? successState : errorState);
        }, 2000);
      }
    );
  };

	handleDone = () => {
		this.setState({ showDialog: false, done: false });
	};

	render() {
		const { users, currentApproval, deleteDialog, deletion } = this.state;

		const options = {
      filterType: "dropdown",
      print: true,
      download: false,
      rowsPerPage: 20,
      rowsPerPageOptions: [10, 15, 20, 50, 100],
      responsive: "simple",
      selectableRows: "none",
      onRowClick: (rowData, rowMeta) => {
        console.log(typeof rowData, rowData);
        if (rowData !== null && typeof rowData === "object") {
          if (rowData.hasOwnProperty("type")) {
            if (rowData.type === "a") {
              return false;
            }
          }
        }
        const currentApproval = this.state.users[rowMeta.dataIndex];
        currentApproval.override_invitation_domain = this.state.domains[0].url;
        currentApproval.override_university_id = currentApproval.university_id;
        currentApproval.override_invitation_tracking = "EAZ";
        this.setState(
          {
            currentApproval,
            showDialog: true,
          },
        );
      },
    };

		const columns = [
			{
				name: 'id',
				label: 'ID',
				options: {
					sort: true,
					filter: true,
					display: false
				}
			},
			{
				name: 'created_at',
				label: 'Created',
				options: {
					customBodyRender: (value) => {
						const calendarStrings = {
							lastDay: '[Yesterday at] LT',
							sameDay: '[Today at] LT',
							nextDay: '[Tomorrow at] LT',
							lastWeek: '[last] dddd [at] LT',
							nextWeek: 'dddd [at] LT',
							sameElse: 'L'
						};
						return <Moment calendar={calendarStrings}>{value}</Moment>;
					}
				}
			},
			{
				name: 'email',
				label: 'Email'
			},
			{
				name: 'first_name',
				label: 'First Name',
				options: {
					sort: false,
					filter: false
				}
			},
			{
				name: 'middle_name',
				label: 'Middle Name',
				options: {
					sort: false,
					filter: false,
					display: false
				}
			},
			{
				name: 'last_name',
				label: 'Last Name',
				options: {
					sort: false,
					filter: false
				}
			},
			{
				name: 'country',
				label: 'Country'
			},
			{
				name: 'state',
				label: 'State'
			},
			{
				name: 'city',
				label: 'City'
			},
			{
				name: 'university_id',
				label: 'Chapter Code'
			},
			{
				name: 'contact_num',
				label: 'Contact Number',
				options: {
					display: false
				}
			},
			{
				name: 'status',
				label: 'Status',
				options: {
					display: false
				}
			},
			{
				name: 'pdf',
				label: 'Transcript PDF',
				options: {
					filter: false,
					sort: false,
					customBodyRender: function (value) {
						if (value === undefined) return null;
						if (value.trim().length === 0) return null;
						const name = value.split('/');
						return (
							<a href={value + '?source=datamule'} target="_blank">
								{name[name.length - 1]}
							</a>
						);
					}
				}
			}
		];

		return (
			<Grid container className="backgrounded">
				<Drawer auth={this.props.auth}>
					<Grid container>
						<Grid item>
							<MUIDataTable
								title={'Eligibility submits List'}
								data={users}
								columns={columns}
								options={options}
							/>
						</Grid>
					</Grid>
				</Drawer>
				<Dialog
					onClose={this.handleClose}
					aria-labelledby="customized-dialog-title"
					open={this.state.showDialog}
				>
					<DialogTitle
              id="customized-dialog-title"
              onClose={this.handleClose}
            >
              {deleteDialog
                ? "Delete from Wordpress"
                : `${currentApproval.first_name} ${currentApproval.last_name} (${currentApproval.email})`}
              <IconButton
                aria-label="close"
                onClick={this.handleClose}
                style={{
                  position: "absolute",
                  right: 10,
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
						{deleteDialog ? (
              <>
                <DialogContent>
                  <DialogContentText
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    id="alert-dialog-description"
                  >
                    {deletion === "confirmed" ? (
                      <>
                        <Typography>
                          The record was deleted successfully!
                        </Typography>
                        <CheckCircleOutlineIcon style={{ color: "green" }} />
                      </>
                    ) : deletion === "not-found" ? (
                      <>
                        <Typography>
                          This student is not in present in Wordpress
                        </Typography>
                        <ErrorIcon color="error" />
                      </>
                    ) : deletion === "error" ? (
                      <>
                        <Typography>
                          An error has occured during deletion!
                        </Typography>
                        <ErrorIcon color="error" />
                      </>
                    ) : (
                      <>
                        Are you sure that you want to delete&nbsp;
                        <Typography
                          style={{ fontWeight: "800" }}
                        >{`${currentApproval.first_name} ${currentApproval.last_name}`}</Typography>
                        &nbsp; from Wordpress?
                      </>
                    )}
                  </DialogContentText>
                </DialogContent>
                {deletion === null ? (
                  <DialogActions>
                    <Button
                      onClick={() => {
                        this.setState({
                          deletion: null,
                          deleteDialog: false,
                        });
                      }}
                    >
                      No
                    </Button>
                    <Button
                      onClick={() => {
                        this.handleDelete(currentApproval.id);
                      }}
                      autoFocus
                    >
                      Yes
                    </Button>
                  </DialogActions>
                ) : (
                  ""
                )}
              </>
            ) : (
              <>
					<DialogContent>
						<DialogContentText variant="body1">
							You can remap the user to any of the chapters available, note that this action will save the remap only if "Approve and Send invitation email" is clicked.
						</DialogContentText>
						<br />
						<FormControl>
							<FormLabel>Chapter</FormLabel>
							<Select
								name="chapter"
								native
								value={currentApproval.override_university_id}
								onChange={this.handleSelectChange}
							>
								{this.state.chapters.map((v) => {
									return <option value={v.id}>{v.id} - {v.name}</option>
								})}
							</Select>
						</FormControl>
						<br />
						<br />
						<FormControl style={{width: '100%'}}>
							<FormLabel>Invitation domain</FormLabel>
							<Select
								
								name="domain"
								native
								value={currentApproval.override_invitation_domain}
								onChange={this.handleSelectChange}
							>
								{this.state.domains.map((v, i) => {
									return <option value={v.url}>{v.name} - {v.url}</option>
								})}
							</Select>
						</FormControl>
						<br />
						<br />
						<FormControl style={{width: '100%'}}>
							<FormLabel>Invitation tracking ({currentApproval.override_university_id}-XXXXXX-{currentApproval.override_invitation_tracking})</FormLabel>
							<TextField id="invtracking" helperText="Will be created after approval" value={currentApproval.override_invitation_tracking} onChange={this.handleSelectChange} />
						</FormControl>
					</DialogContent>
					<DialogActions style={{justifyContent: 'center'}}>
						<Button onClick={this.hanldeApprove} color="primary">
							Approve and Send invitation email
						</Button>
					</DialogActions>
					<DialogContent>
						<h1 style={{textAlign: 'center'}}>OR</h1>
					</DialogContent>
					<DialogActions style={{justifyContent: 'center'}}>
						<Button onClick={this.hanldeReject} color="secondary">
							Reject submission with email
						</Button>
					</DialogActions>
					<DialogActions style={{ justifyContent: "center" }}>
                  <Button
                    variant="text"
                    startIcon={<DeleteIcon />}
                    onClick={() => {
                      this.setState({
                        deleteDialog: "true",
                      });
                    }}
                    style={{ color: "#ff0000" }}
                  >
                    Delete from Wordpress
                  </Button>
                </DialogActions>
              </>
            )}
				</Dialog>
			</Grid>
		);
	}
	handleSelectClose = (e) => {
		console.log(e.target.id)
		this.setState({ showDialog: false })
	}
	handleSelectChange = (e) => {
		console.log(e.target)
		const currentApproval = this.state.currentApproval;
		if (e.target.name === "domain") {
			currentApproval.override_invitation_domain = e.target.value;
		} else if (e.target.id === "invtracking") {
			currentApproval.override_invitation_tracking = e.target.value.toUpperCase();
		} else {
			currentApproval.override_university_id = e.target.value;
		}

		this.setState({ currentApproval })
	}

	hanldeApprove = () => {
		const { currentApproval } = this.state;
		this.setState({ approving: true });
		
		console.log(currentApproval);
		Axios.post('/api/orm/approve-single', { currentApproval }).then((res) => {
			this.setState({ approving: false, done: true, showDialog: false });
			this.getJobs();
		});
	};

	hanldeReject = () => {
		const { currentApproval } = this.state;
		this.setState({ approving: true });
		
		console.log(currentApproval);
		Axios.post('/api/orm/reject-single', { currentApproval }).then((res) => {
			this.setState({ approving: false, done: true, showDialog: false });
			this.getJobs();
		});
	}
}

/*
{!done && (
						<DialogContent dividers style={{ textAlign: 'center' }}>
							<CircularProgress style={{ margin: '0 auto' }} />
						</DialogContent>
					)}
					{done && [
						<DialogContent dividers style={{ textAlign: 'center' }}>
							Prospect(s) approval done.
						</DialogContent>,
						<DialogActions style={{ textAlign: 'center' }}>
							<Button onClick={this.handleDone} color="primary">
								Close
							</Button>
						</DialogActions>
					]}
*/