import React, {Component} from 'react';
import Drawer from '../../components/drawer';
import {Button, CircularProgress, FormControl, FormLabel, Grid, Select} from '@material-ui/core';
import Axios from 'axios';
import TextField from "@material-ui/core/TextField";

export default class ChangeUsersRoles extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            finished: false,
            fileIsReady: false,
            availableRoles: [],
            countries: [],
            fileToChangeUsersRoles: null,
            selectedRole: null,
            selectedCountryCode: null,
            response: [],
            showErrors: false,
            areJobsRunning: false,
            jobRunningData: null,
            lastProcessedJobData: null,
        }
    }

    toTitleCase = (phrase) => {
        return phrase
            .trim()
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    async componentDidMount() {
        this.setState({ loading: true });
        const availableRoles = await this.getAvailableRoles();
        const countries = await this.getCountries();
        this.setState({ availableRoles, countries, loading: false, selectedRole: availableRoles?.[0]?.role_key, selectedCountryCode: countries?.[0]?.country_code });
        await this.checkPendingJobs();
        await this.getLastProcessedJob();
        setInterval(() => this.checkPendingJobs(), 3000);
    }

    getAvailableRoles = async () => {
        const res = await Axios.get('/api/get_available_roles_to_change', {});
        const data = await res.data;
        if (data.status === 'success') {
            for (let i = 0; i < data.response.length; i++) {
                data.response[i]['role_name'] = this.toTitleCase(data.response[i]['role_name']);
            }
            return data.response;
        }
    }

    getCountries = async () => {
        const res = await Axios.get('/api/get_chapters_countries', {});
        const data = await res.data;
        if (data.status === 'success') {
            for (let i = 0; i < data.response.length; i++) {
                data.response[i]['country'] = this.toTitleCase(data.response[i]['country']);
            }
            return data.response;
        }
    }

    checkPendingJobs = async () => {
        const res = await Axios.get('/api/get_pending_change_users_roles_jobs', {});
        const data = await res.data;
        if (data.status === 'success') {
            if (data.response.length > 0) {
                if (!this.state.areJobsRunning) {
                    window.scrollTo(0, 0);
                }
                this.setState({areJobsRunning: true, jobRunningData: data.response, finished: false});
            } else if (this.state.areJobsRunning) {
                window.scrollTo(0, 0);
                await this.getLastProcessedJob();
                this.setState({areJobsRunning: false, jobRunningData: null, finished: false});
            }
        }
    }

    getLastProcessedJob = async () => {
        const res = await Axios.get('/api/get_last_processed_change_users_roles_job', {});
        const data = await res.data;
        if (data.status === 'success') {
            this.setState({ lastProcessedJobData: data.response });
        }
    }

    downloadChangeUsersRolesTemplate = async () => {
        this.setState({ loading: true });
        const res = await Axios.get('/api/download_change_users_roles_template', {});
        this.setState({ loading: false });
        const data = await res.data;
        const urlData = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data}`;
        const link = document.createElement('a');
        link.href = urlData;
        link.download="change_users_roles_template.xlsx";
        link.click();
        setTimeout(function() { window.URL.revokeObjectURL(urlData)},100);
    }

    downloadUploadedFile = async (filePath, jobID) => {
        const fileName = filePath.split('/').pop();
        const res = await Axios.get('/api/get_uploaded_file?file_name=' + fileName, { responseType: 'arraybuffer' });
        const data = await res.data;
        const base64Data = Buffer.from(data, 'binary').toString('base64');
        const urlData = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64Data}`;
        const link = document.createElement('a');
        link.href = urlData;
        link.download = jobID + '.xlsx';
        link.click();
        setTimeout(function() { window.URL.revokeObjectURL(urlData)},100);
    }

    handleFileSelected = (event) => {
        this.setState({ fileToChangeUsersRoles: event.target.files[0] });
    }

    uploadFileToChangeUsersRoles = async () => {
        this.setState({ loading: true, showErrors: false, response: [] });
        const formData = new FormData();
        formData.append('file', this.state.fileToChangeUsersRoles);
        formData.append('country_code', this.state.selectedCountryCode);
        formData.append('role', this.state.selectedRole);
        const res = await Axios.post('/api/upload_file_to_change_users_roles', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        this.setState({ loading: false });
        const data = await res.data;
        if (data.status === 'success') {
            this.setState({ fileIsReady: true, response: data.response, showErrors: false });
        } else {
            this.setState({ fileIsReady: false, response: data.message, showErrors: true });
        }
    }

    changeUsersRoles = async () => {
        this.setState({ loading: true });
        try {
            const res = await Axios.post('/api/change_users_roles', {
                users_not_registered: this.state.response.users_not_registered,
                users_to_remove_role: this.state.response.users_to_remove_role,
                users_to_add_role: this.state.response.users_to_add_role,
                role: this.state.selectedRole,
                country_code: this.state.selectedCountryCode,
                file_path: this.state.response.file_path,
            }, {timeout: 5000});
            const data = await res.data;
            if (data.status !== 'success') {
                this.setState({fileIsReady: false, response: data.message, showErrors: true, loading: false});
                return;
            }
        } catch (err) {
            if (err.code !== 'ECONNABORTED') {
                this.setState({fileIsReady: false, response: err.message, showErrors: true, loading: false});
                return;
            }
        }
        this.setState({
            loading: false,
            finished: true,
            fileIsReady: false,
            fileToChangeUsersRoles: null,
            selectedRole: null,
            selectedCountryCode: null,
            response: [],
            showErrors: false,
        });
    }

    render() {
        return (
            <>
                <style>
                    {`
                        .last-job-table {
                            width: 100%;
                            border-collapse: collapse;
                            margin-bottom: 20px;
                        }
                        
                        .last-job-table td, .last-job-table th {
                            border: 1px solid #ccc;
                            padding: 8px;
                        }
                        
                        .last-job-table tr:hover {background-color: #eee;}
                        
                        .last-job-table th {
                            padding-top: 12px;
                            padding-bottom: 12px;
                            background-color: #2c387e;
                            color: white;
                        }
                        
                        .last-job-table td {
                            text-align: center;
                        }
                    `}
                </style>
                <Grid container className="backgrounded">
                    <Drawer auth={this.props.auth}>
                        <Grid container md={12}>
                            {this.state.lastProcessedJobData && !this.state.fileIsReady &&
                                <>
                                <Grid item md={12} alignItems="center" justify="center">
                                    <table className={'last-job-table'}>
                                        <thead>
                                        <tr>
                                            <th>Last Task ID</th>
                                            <th>Status</th>
                                            <th>Role</th>
                                            <th>Country</th>
                                            <th>Users Registered</th>
                                            <th>Users Removed Role</th>
                                            <th>Users Added Role</th>
                                            <th>Created Date</th>
                                            <th>Finished Date</th>
                                            <th>File Uploaded</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{this.state.lastProcessedJobData._id}</td>
                                                <td>{this.state.lastProcessedJobData.status}</td>
                                                <td>{this.state.lastProcessedJobData.json_data.role}</td>
                                                <td>{this.state.lastProcessedJobData.json_data.country_code}</td>
                                                <td>{this.state.lastProcessedJobData.json_data.users_not_registered.length}</td>
                                                <td>{this.state.lastProcessedJobData.json_data.users_to_remove_role.length}</td>
                                                <td>{this.state.lastProcessedJobData.json_data.users_to_add_role.length}</td>
                                                <td>{this.state.lastProcessedJobData.created_date}</td>
                                                <td>{this.state.lastProcessedJobData.finished_date}</td>
                                                <td>
                                                    <Button color="primary" variant="raised"
                                                            onClick={async () => {this.downloadUploadedFile(this.state.lastProcessedJobData.file_uploaded_path, this.state.lastProcessedJobData._id)}}>Download File
                                                    </Button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Grid>
                                </>
                            }
                            {!this.state.areJobsRunning && this.state.finished &&
                                <>
                                <Grid item md={12} alignItems="center" justify="center">
                                    <h1>Task uploaded, please come back later to check the results</h1>
                                </Grid>
                                </>
                            }
                            {!this.state.areJobsRunning && !this.state.finished &&
                                <>
                                    {!this.state.fileIsReady &&
                                        <Grid container md={12}>
                                            <Grid item md={12} alignItems="center" justify="center">
                                                <Button disabled={this.state.loading} color="primary" variant="raised"
                                                        onClick={this.downloadChangeUsersRolesTemplate}
                                                        style={{marginBottom: 20, marginRight: 10}}>Download
                                                    Template</Button>
                                            </Grid>
                                            <Grid item md={12} alignItems="center" justify="center">
                                                <FormControl style={{width: '100%', marginBottom: '30px'}}>
                                                    <FormLabel>Role</FormLabel>
                                                    <Select
                                                        name="role"
                                                        native
                                                        onChange={(e) => this.setState({selectedRole: e.target.value})}
                                                    >
                                                        {this.state.availableRoles.length > 0 && this.state.availableRoles.map((roleData) => {
                                                            return <option
                                                                value={roleData.role_key}>{roleData.role_name}</option>
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item md={12} alignItems="center" justify="center">
                                                <FormControl style={{width: '100%', marginBottom: '30px'}}>
                                                    <FormLabel>Country</FormLabel>
                                                    <Select
                                                        name="country"
                                                        native
                                                        onChange={(e) => this.setState({selectedCountryCode: e.target.value})}
                                                    >
                                                        {this.state.countries.length > 0 && this.state.countries.map((countryData) => {
                                                            if (countryData.country_code && countryData.country) {
                                                                return <option
                                                                    value={countryData.country_code}>{countryData.country}</option>
                                                            }
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item md={12} alignItems="center" justify="center">
                                                <FormControl style={{width: '100%', marginBottom: '30px'}}>
                                                    <FormLabel>File</FormLabel>
                                                    <TextField style={{marginTop: '16px'}} type={"file"}
                                                               inputProps={{accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}}
                                                               onChange={this.handleFileSelected}/>
                                                </FormControl>
                                            </Grid>
                                            <Grid item md={12} alignItems="center" justify="center">
                                                <Button disabled={this.state.loading} color="primary" variant="raised"
                                                        onClick={this.uploadFileToChangeUsersRoles}
                                                        style={{marginBottom: 20, marginRight: 10}}>Upload File</Button>
                                            </Grid>
                                        </Grid>
                                    }
                                    {this.state.loading &&
                                        <CircularProgress/>
                                    }
                                    {!this.state.fileIsReady &&
                                        <Grid item md={12} alignItems="center" justify="center">
                                        <pre style={{marginBottom: 20}}>
                                            {this.state.response &&
                                                <span>
                                                    {this.state.showErrors &&
                                                        <h3 style={{color: 'red'}}>Could not upload the file, the following
                                                            errors were found:</h3>
                                                    }
                                                    <pre>
                                                        {this.state.response.toString()}
                                                    </pre>
                                                </span>
                                            }
                                        </pre>
                                        </Grid>
                                    }
                                    {this.state.fileIsReady &&
                                        <Grid item md={12} alignItems="center" justify="center">
                                            {this.state.response.users_not_registered && this.state.response.users_not_registered.length > 0 &&
                                                <span>
                                                <h1 style={{color: 'goldenrod'}}>ATTENTION: The following users are not registered in the system and will be created with the role {this.state.selectedRole} in GoldenKey</h1>
                                                <ul>
                                                    {this.state.response.users_not_registered.map((row) => {
                                                        return <li>{row[0] + ' ' + row[1] + ' (' + row[2] + ') [' + row[3] + ']'}</li>
                                                    })}
                                                </ul>
                                                <hr/>
                                            </span>
                                            }
                                            {this.state.response.users_to_remove_role && this.state.response.users_to_remove_role.length > 0 &&
                                                <span>
                                                <h3 style={{color: 'red'}}>The following users will have the role {this.state.selectedRole} removed</h3>
                                                <ul>
                                                    {this.state.response.users_to_remove_role.map((row) => {
                                                        return <li>{row[0] + ' ' + row[1] + ' (' + row[2] + ') [' + row[3] + ']'}</li>
                                                    })}
                                                </ul>
                                            </span>
                                            }
                                            {this.state.response.users_to_add_role && this.state.response.users_to_add_role.length > 0 &&
                                                <span>
                                                <h3 style={{color: 'green'}}>The following users will have the role {this.state.selectedRole} added</h3>
                                                <ul>
                                                    {this.state.response.users_to_add_role.map((row) => {
                                                        return <li>{row[0] + ' ' + row[1] + ' (' + row[2] + ') [' + row[3] + ']'}</li>
                                                    })}
                                                </ul>
                                            </span>
                                            }
                                            {this.state.response.users_already_have_role && this.state.response.users_already_have_role.length > 0 &&
                                                <span>
                                                <h3 style={{color: 'green'}}>The following users already have the role {this.state.selectedRole}</h3>
                                                <ul>
                                                    {this.state.response.users_already_have_role.map((row) => {
                                                        return <li>{row[0] + ' ' + row[1] + ' (' + row[2] + ') [' + row[3] + ']'}</li>
                                                    })}
                                                </ul>
                                            </span>
                                            }
                                            <h2>
                                                Are you sure you want to proceed with this changes?
                                                <small style={{color: 'grey', fontSize: '0.6em', fontWeight: 'lighter'}}>
                                                    <br/>(This process will take a long time)
                                                </small>
                                            </h2>
                                            <Button disabled={this.state.loading} color="primary" variant="raised"
                                                    style={{marginBottom: 20, marginRight: 10}}
                                                    onClick={this.changeUsersRoles}>
                                                Yes, I'm sure
                                            </Button>
                                            <Button disabled={this.state.loading} color="secondary" variant="raised"
                                                    onClick={() => {
                                                        this.setState({
                                                            loading: false,
                                                            finished: false,
                                                            fileIsReady: false,
                                                            fileToChangeUsersRoles: null,
                                                            selectedRole: null,
                                                            selectedCountryCode: null,
                                                            response: [],
                                                            showErrors: false,
                                                        })
                                                    }}
                                                    style={{marginBottom: 20, marginRight: 10}}>
                                                No, cancel
                                            </Button>
                                        </Grid>
                                    }
                                </>
                            }
                            {this.state.areJobsRunning &&
                                <Grid item md={12} alignItems="center" justify="center">
                                    <h1>Processing the current task, please come back later</h1>
                                    {this.state.jobRunningData.map((job) => {
                                        const jobData = job.json_data;
                                        return <>
                                            <ul>
                                                <li><b>Upload Date:</b> {job.created_date}</li>
                                                <li><b>Status:</b> {job.status}</li>
                                                <li><b>Role:</b> {jobData.role}</li>
                                                <li><b>Country Code:</b> {jobData.country_code}</li>
                                                <li><b>Progress:</b> {job.total_users_processed} / {job.total_users_to_process}</li>
                                            </ul>
                                            {jobData && jobData.users_not_registered && jobData.users_not_registered.length > 0 &&
                                                <>
                                                    <h3 style={{color: 'green'}}><b>New users being created with the role {jobData.role}:</b> {jobData.users_not_registered.length}</h3>
                                                    <ul>
                                                        {jobData.users_not_registered.map((row) => {
                                                            return <li>{row[0] + ' ' + row[1] + ' (' + row[2] + ') [' + row[3] + ']'}</li>
                                                        })}
                                                    </ul>
                                                </>
                                            }
                                            {jobData && jobData.users_to_add_role && jobData.users_to_add_role.length > 0 &&
                                                <>
                                                    <h3 style={{color: 'green'}}><b>Existing users being updated with the role {jobData.role}:</b> {jobData.users_to_add_role.length}</h3>
                                                    <ul>
                                                        {jobData.users_to_add_role.map((row) => {
                                                            return <li>{row[0] + ' ' + row[1] + ' (' + row[2] + ') [' + row[3] + ']'}</li>
                                                        })}
                                                    </ul>
                                                </>
                                            }
                                            {jobData && jobData.users_to_remove_role && jobData.users_to_remove_role.length > 0 &&
                                                <>
                                                    <h3 style={{color: 'red'}}><b>Existing users being removed from the role {jobData.role}:</b> {jobData.users_to_remove_role.length}</h3>
                                                    <ul>
                                                        {jobData.users_to_remove_role.map((row) => {
                                                            return <li>{row[0] + ' ' + row[1] + ' (' + row[2] + ') [' + row[3] + ']'}</li>
                                                        })}
                                                    </ul>
                                                </>
                                            }
                                        </>
                                    })}
                                </Grid>
                            }
                        </Grid>
                    </Drawer>
                </Grid>
            </>
        );
    }
}
