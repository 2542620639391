import React, { Component } from 'react';
import Drawer from '../../components/drawer';

import { Grid, Dialog, CircularProgress, Button, DialogContent, DialogTitle, DialogActions, IconButton, DialogContentText, FormControl, FormLabel, Select, TextField } from '@material-ui/core';
import Axios from 'axios';
import MUIDataTable from 'mui-datatables';
import Moment from 'react-moment';
import CloseIcon from '@material-ui/icons/Close';

export default class Approved extends Component {
	state = {
		users: [],
		loading: false,
		showDialog: false,
        done: false,
        currentApproval: false,
        approving: false,
        chapters: [],
		selectOpen: false,
		domains: [
			{
				name: "GoldenKey main property (goldenkey.org)",
				url: "https://www.goldenkey.org"
			},
			{
				name: "Malaysia domain (.MY)",
				url: "http://malaysia.goldenkey.org/"
			},
			{
				name: "South Africa (.ZA)",
				url: "https://yes.joingoldenkey.org.za"
			},
			{
				name: "Staging",
				url: "https://gk-stage.trueinteraction.com"
			},
			{
				name: "Preprod",
				url: "https://gk-preprod.trueinteraction.com"
			}
		]
	};

	componentDidMount() {
		this.getJobs();
	}

	handleClose = () => {
		this.setState({ showDialog: false, currentApproval: false });
	};

	async getJobs() {
		this.setState({ loading: true });
		const { match: { params } } = this.props;
		const res = await Axios.get(`/api/orm/users/rejected`);
        var users = await res.data;
        const chaptersRes = await Axios.get('/api/chapters');
		let chapters = await chaptersRes.data;
		this.setState({ users, loading: false, chapters });
	}

	resendInvitation(rows) {
		console.log(rows);
		const { users } = this.state;
		this.setState({ showDialog: true });
		const ids = rows.data.map((ele) => {
			return users[ele.dataIndex].id;
		});

		console.log(ids);
		const domain = `${window.location.protocol}//${window.location.host.split(':')[0]}`;
		Axios.post('/api/orm/resend', { ids, domain }).then((res) => {
			this.setState({ done: true });
			this.getJobs();
		});
	}

	handleDone = () => {
		this.setState({ showDialog: false, done: false });
	};

	render() {
		const { users, done, currentApproval } = this.state;

		const options = {
			filterType: 'dropdown',
			selectableRows: 'none',
			print: true,
			download: false,
			rowsPerPage: 15,
			rowsPerPageOptions: [ 10, 15, 20, 50, 100 ],
			responsive: 'stacked',
			selectableRowsOnClick: 'none',
			customToolbarSelect: (selectedRows) => {
				const { data } = selectedRows;
				const { showDialog } = this.state;

				return (
					<div>
						<Button
							style={{ marginRight: 20 }}
							variant="contained"
							color="primary"
							onClick={() => this.resendInvitation(selectedRows)}
						>
							Resend invitation for {selectedRows.data.length} prospect(s)
							{showDialog && (
								<CircularProgress
									size={20}
									style={{ marginLeft: 5, marginRight: 5 }}
									color="secondary"
								/>
							)}
						</Button>
					</div>
				);
            },
            onCellClick: (rowData, rowMeta) => {
				console.log(typeof rowData);
				if (typeof rowData === "object") {
					if (rowData.hasOwnProperty("type")) {
						if (rowData.type === "a") {
							return false;
						}
					}
				}
				const currentApproval = this.state.users[rowMeta.dataIndex];
				currentApproval.override_invitation_domain = this.state.domains[0].url;
				currentApproval.override_university_id = currentApproval.university_id;
				currentApproval.override_invitation_tracking = 'EAZ';
				this.setState({
					currentApproval,
					showDialog: true
				})
			}
		};

		const columns = [
			{
				name: 'id',
				label: 'ID',
				options: {
					sort: true,
					filter: false
				}
			},
			{
				name: 'created_at',
				label: 'Created',
				options: {
					customBodyRender: (value, tableMeta, updateValue) => {
						console.log('DATES', value);
						const calendarStrings = {
							lastDay: '[Yesterday at] LT',
							sameDay: '[Today at] LT',
							nextDay: '[Tomorrow at] LT',
							lastWeek: '[last] dddd [at] LT',
							nextWeek: 'dddd [at] LT',
							sameElse: 'L'
						};
						return <Moment calendar={calendarStrings}>{value}</Moment>;
					}
				}
			},
			{
				name: 'email',
				label: 'Email'
			},
			{
				name: 'first_name',
				label: 'First Name',
				options: {
					sort: false,
					filter: false
				}
			},
			{
				name: 'middle_name',
				label: 'Middle Name',
				options: {
					sort: false,
					filter: false
				}
			},
			{
				name: 'last_name',
				label: 'Last Name',
				options: {
					sort: false,
					filter: false
				}
			},
			{
				name: 'university_id',
				label: 'Chapter Code'
			},
			{
				name: 'contact_num',
				label: 'Contact Number'
            },
            {
				name: 'image',
				label: 'Transcript PDF',
				options: {
					filter: false,
					sort: false,
					customBodyRender: function (image) {
                        const value = image.guid
						if (value === undefined) return null;
						if (value.trim().length === 0) return null;
						const name = value.split('/');
						return (
							<a href={value + '?source=datamule'} target="_blank">
								{name[name.length - 1]}
							</a>
						);
					}
				}
			}
			// {
			// 	name: 'primary_invitation',
			// 	label: 'Primary Invitation'
			// },
			// {
			// 	name: 'invitation_tracking',
			// 	label: 'Invitation tracking'
			// },
			// {
			// 	name: 'url',
			// 	label: 'Registration URL',
			// 	options: {
			// 		filter: false,
			// 		sort: false,
			// 		customBodyRender: function(value) {
			// 			return (
			// 				<a href={value} target="_blank">
			// 					{value}
			// 				</a>
			// 			);
			// 		}
			// 	}
			// }
		];

		return (
			<Grid container className="backgrounded">
				<Drawer auth={this.props.auth}>
					<Grid container>
						<Grid item md={12}>
							<MUIDataTable
								title={'Approved prospects List'}
								data={users}
								columns={columns}
								options={options}
							/>
						</Grid>
					</Grid>
				</Drawer>
				<Dialog
					onClose={this.handleClose}
					aria-labelledby="customized-dialog-title"
					open={this.state.showDialog}
				>
					<DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
						{currentApproval.first_name} {currentApproval.last_name} ({currentApproval.email})
						<IconButton aria-label="close" onClick={this.handleClose} style={{
							position: 'absolute',
							right: 10,
							top: 10,
							color: '#333'
						}}>
							<CloseIcon />
						</IconButton>
					</DialogTitle>
					<DialogContent>
						<DialogContentText variant="body1">
							You can remap the user to any of the chapters available, note that this action will save the remap only if "Approve and Send invitation email" is clicked.
						</DialogContentText>
						<br />
						<FormControl>
							<FormLabel>Chapter</FormLabel>
							<Select
								name="chapter"
								native
								value={currentApproval.override_university_id}
								onChange={this.handleSelectChange}
							>
								{this.state.chapters.map((v) => {
									return <option value={v.id}>{v.id} - {v.name}</option>
								})}
							</Select>
						</FormControl>
						<br />
						<br />
						<FormControl style={{width: '100%'}}>
							<FormLabel>Invitation domain</FormLabel>
							<Select
								
								name="domain"
								native
								value={currentApproval.override_invitation_domain}
								onChange={this.handleSelectChange}
							>
								{this.state.domains.map((v, i) => {
									return <option value={v.url}>{v.name} - {v.url}</option>
								})}
							</Select>
						</FormControl>
						<br />
						<br />
						<FormControl style={{width: '100%'}}>
							<FormLabel>Invitation tracking ({currentApproval.override_university_id}-XXXXXX-{currentApproval.override_invitation_tracking})</FormLabel>
							<TextField id="invtracking" helperText="Will be created after approval" value={currentApproval.override_invitation_tracking} onChange={this.handleSelectChange} />
						</FormControl>
					</DialogContent>
					<DialogActions style={{justifyContent: 'center'}}>
						<Button onClick={this.hanldeApprove} color="primary">
							Approve and Send invitation email
						</Button>
					</DialogActions>
				</Dialog>
			</Grid>
		);
	}
	handleSelectChange = (e) => {
		console.log(e.target)
		const currentApproval = this.state.currentApproval;
		if (e.target.name === "domain") {
			currentApproval.override_invitation_domain = e.target.value;
		} else if (e.target.id === "invtracking") {
			currentApproval.override_invitation_tracking = e.target.value.toUpperCase();
		} else {
			currentApproval.override_university_id = e.target.value;
		}

		this.setState({ currentApproval })
	}
    hanldeApprove = () => {
		const { currentApproval } = this.state;
		this.setState({ approving: true });
		
		console.log(currentApproval);
		Axios.post('/api/orm/approve-single', { currentApproval }).then((res) => {
			this.setState({ approving: false, done: true, showDialog: false });
			this.getJobs();
		});
	};
}
