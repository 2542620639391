import { Button, Grid } from "@material-ui/core";
import React, { Component } from "react";
import { RowContext } from "./RowContextProvider";

class ExpandedRowInfo extends Component {
  render() {
    return (
      <RowContext.Consumer>
        {({ data, disabledButton, exportBtnHandler }) => {
          return (
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
              style={{ margin: 10, paddingLeft: 20, paddingRight: 20 }}
            >
              <Grid md={12} item>
                <ol>
                  {data.map(function (name, index) {
                    return <li key={index}>{name}</li>;
                  })}
                </ol>
                <Button
                  style={{ marginTop: 10 }}
                  color="secondary"
                  variant="contained"
                  disabled={disabledButton || !data[4]}
                  onClick={exportBtnHandler}
                >
                  Export To WordPress
                </Button>
                <span
                  className="error-message"
                  style={{
                    fontSize: "14px",
                    color: "#e91e63",
                    margin: "10px",
                    display: "inline-block",
                    top: "6px",
                    position: "relative",
                  }}
                ></span>
              </Grid>
            </Grid>
          );
        }}
      </RowContext.Consumer>
    );
  }
}

export default ExpandedRowInfo;
