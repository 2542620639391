import React, { Component } from 'react';
import Drawer from '../../components/drawer';
import { Grid, Paper, Table, TableHead, TableRow, TableCell, TableBody, Button, Tooltip, CircularProgress } from '@material-ui/core';
import Axios from 'axios';
import Moment from '../../components/Moment';
import Swal from 'sweetalert2';

import { CloudDownload, Description, FileCopy } from '@material-ui/icons';

export default class Home extends Component {
	state = {
		jobs: [],
		exporting: false,
		loadingDuplicates: {}
	};

	constructor() {
		super();
		this.getJobs();
	}

	componentWillReceiveProps() {
		this.getJobs();
	}

	async getJobs() {
		const res = await Axios.get('/api/jobs');
		const jobs = await res.data;
		this.setState({ jobs });
	}

	async checkDuplicatedJoinCodes(jobId) {
		let loadingDuplicates = { ...this.state.loadingDuplicates };
		loadingDuplicates[jobId] = true;
		this.setState({ loadingDuplicates });
		const res = await Axios.post(`/api/check-duplicated-join-codes`, { job_id: jobId });
		const data = await res.data;
		loadingDuplicates = { ...this.state.loadingDuplicates };
		loadingDuplicates[jobId] = false;
		this.setState({ loadingDuplicates });
		if (data.status === 'success') {
			let message = '';
			if (Object.keys(data.response).length > 0) {
				for (let [email, joinCode] of Object.entries(data.response)) {
					message += `${email} - ${joinCode}\n`;
				}
				await Swal.fire({
					title: 'Found Duplicates!',
					text: message,
					icon: 'error',
					confirmButtonText: 'Ok'
				});
			} else {
				await Swal.fire({
					title: 'No duplicates found',
					icon: 'success',
					confirmButtonText: 'Ok'
				});
			}
		} else if (data.status === 'error') {
			await Swal.fire({
				title: 'Error!',
				text: data.message,
				icon: 'error',
				confirmButtonText: 'Ok'
			});
		}
	};

	render() {
		const { jobs, exporting } = this.state;
		return (
			<Grid container className="backgrounded">
				<Drawer  auth={this.props.auth}>
					<h1>Jobs</h1>
					<Paper>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell style={{ width: '33%' }}> Job ID</TableCell>
									<TableCell style={{ width: '2%' }}>Chapter</TableCell>
									<TableCell style={{ width: '2%' }}>Source</TableCell>
									<TableCell style={{ width: '2%' }}>Season</TableCell>
									<TableCell style={{ width: '10%' }}>Creator</TableCell>
									<TableCell style={{ width: '17%' }}>Created on</TableCell>
									<TableCell style={{ width: '10%' }}>Import count</TableCell>
									<TableCell style={{ width: '19%' }}>Operations</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{!jobs.length && (
									<TableRow>
										<TableCell colSpan={5}>No Jobs found</TableCell>
									</TableRow>
								)}
								{jobs.map((v, i) => (
									<TableRow key={i}>
										<TableCell>{v.jobID}</TableCell>
										<TableCell>{v.chapter}</TableCell>
										<TableCell>{v.source}</TableCell>
										<TableCell>{v.season}</TableCell>
										<TableCell>{v.owner}</TableCell>
										<TableCell>
											<Moment>{v.created}</Moment>
										</TableCell>
										<TableCell>{v.count}</TableCell>
										<TableCell>
											<Tooltip title="Contact list" placement="top">
												<Button
													onClick={() => {
														this.props.history.push(`/job/${v.jobID}`);
													}}
													color="default"
													variant="contained"
												style={{ fontSize: 12, margin: 5 }}
												>
													Details
													<Description
														style={{ marginLeft: 5 }}
													/>
												</Button>
											</Tooltip>
											<Tooltip title="Check Duplicates" placement="top">
												<Button
													onClick={() => {
														this.checkDuplicatedJoinCodes(v.jobID);
													}}
													color="default"
													variant="contained"
													style={{ fontSize: 12, margin: 5 }}
												>
													Check Duplicates
													{!this.state.loadingDuplicates[v.jobID] &&
														<FileCopy
															style={{marginLeft: 5}}
														/>
													}
													{this.state.loadingDuplicates[v.jobID] &&
														<CircularProgress
															style={{marginLeft: 5}}
														/>
													}
												</Button>
											</Tooltip>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</Paper>
				</Drawer>
			</Grid>
		);
	}

	saveAsXSLX = (jobId) => async () => {
		this.setState({ exporting: true });
		const res = await Axios.post(`/api/generate/xslx/${jobId}`);
		const data = await res.data;
		this.setState({ exporting: false }, () => {
			window.location.href = data.url;
		});
	};
}
