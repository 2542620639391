import React, { Component } from 'react';
import Drawer from '../../components/drawer';
import { Grid, CircularProgress, Button, Typography, FormGroup, Paper, NativeSelect, FormLabel, Input } from '@material-ui/core';
import Axios from 'axios';



export default class ChapterInvitations extends Component {

    constructor() {
        super()
        this.state = {
            loading: false,
            finished: false,
            chapters: [],
            chapter: "",
            expiration_date: new Date(),
            invitation_tracking: "EAZ",
        }
    }

    componentWillMount() {
        this.getMappings();
    }

    getMappings = async () => {
        const res = await Axios('/api/chapters');
        const chapters = await res.data;
        this.setState({ chapters });
    };

    handleChange = (name) => (e) => {
        let value = e.target.value;
        if (name === "invitation_tracking") value = value.toUpperCase();
        this.setState({
            [name]: value
        });
    };

    confirm = async () => {
        const { expiration_date, chapter, invitation_tracking } = this.state;

        const res = await Axios.post('/api/chapter-invitations/generate', { expiration_date, chapter, invitation_tracking });
        const data = await res.data;
        window.location.href = '/chapter-invitations/listing';
    }

    render() {
        const { loading, finished, chapters } = this.state;
        return (
            <Grid container className="backgrounded">
                <Drawer auth={this.props.auth}>
                    <Grid container md={12}>

                        <Grid item md={12} alignItems="center" justify="center">
                            <Typography variant="headline" style={{ marginBottom: 20 }}>Please select the options from the following to create invitations</Typography>
                            <Paper style={{ padding: 30, maxWidth: '50vw', width: 500, marginBottom: 30 }}>
                                <FormGroup>
                                    <FormLabel>Chapter</FormLabel>
                                    <NativeSelect fullWidth style={{ marginBottom: 30 }} onChange={this.handleChange('chapter')}>
                                        <option value={false}>- None -</option> 
									{chapters.length && chapters.map((v) => (
                                            <option key={v.id} value={v.id}>
                                                {v.name}
                                            </option>
                                        ))}
                                    </NativeSelect>
                                    <FormLabel >Invitation tracking</FormLabel>
                                    <Input style={{ marginBottom: 30 }}
                                        margin="dense"
                                        onChange={this.handleChange('invitation_tracking')}
                                        value={this.state.invitation_tracking}
                                        placeholder="EAZ"
                                        inputProps={{
                                            type: 'text',
                                            'aria-labelledby': 'input-slider',
                                        }}
                                    />
                                    <FormLabel>Expiration Date</FormLabel>
                                    <Input
                                        margin="dense"
                                        onChange={this.handleChange('expiration_date')}
                                        inputProps={{
                                            type: 'date',
                                        }}
                                    />
                                    <br/>
                                </FormGroup>
                            </Paper>
                            <Button disabled={loading} color="primary" variant="raised" onClick={this.confirm} style={{ marginBottom: 20, marginRight: 10 }}>Generate</Button>
                            {loading &&
                                <CircularProgress />
                            }
                            {finished &&
                                <Typography variant="headline" style={{ marginBottom: 20 }}></Typography>
                            }
                        </Grid>

                    </Grid>
                </Drawer>
            </Grid>
        )
    }
}
