import React, { Component } from 'react';
import Drawer from '../../components/drawer';
import {
    Grid,
    CircularProgress,
    Button,
    Typography,
    Select,
    Table,
    TableHead,
    TableRow,
    TableCell
} from '@material-ui/core';
import Axios from 'axios';

export default class CountHubspotVotes extends Component {

    constructor() {
        super()
        this.state = {
            loading: false,
            finished: false,
            response: [],
            votations: [],
            selectedVotationId: null,
            error: null
        }
    }

    componentDidMount() {
        this.getHubspotVotations();
    }

    getHubspotVotations = async () => {
        this.setState({ votations: [], loading: true, });
        const res = await Axios.get('/api/get-hubspot-votations');
        this.setState({ loading: false });
        const data = await res.data;
        console.log(data);
        if (data.status === 'success') {
            this.setState({ votations: data.response, selectedVotationId: data.response[0].hs_object_id });
        }
    }

    countHubspotVotes = async () => {
        this.setState({ response: [], loading: true, finished: false, error: null });
        const res = await Axios.post('/api/count-hubspot-votes', {
            votation_id: this.state.selectedVotationId
        });
        this.setState({ loading: false, finished: true });
        const data = await res.data;
        if (data.error) {
            this.setState({ error: data.error });
        } else if (data.response) {
            this.setState({ response: data.response });
        }
    }

    render() {
        const { loading, finished, error } = this.state;
        return (
            <Grid container className="backgrounded">
                <Drawer auth={this.props.auth}>
                    <Grid container md={12}>
                        <Grid item md={12} alignItems="center" justify="center">
                            {!finished && !loading && this.state.votations.length > 0 &&
                                <>
                                    <div>
                                        <Typography variant="title" style={{ marginBottom: 20 }}>Select Votation</Typography>
                                        <Select
                                            style={{ marginBottom: 20 }}
                                            native
                                            onChange={(e) => this.setState({ selectedVotationId: e.target.value })}
                                        >
                                            {this.state.votations.map((votation) => {
                                                return (<option value={votation.hs_object_id} key={votation.hs_object_id}>{votation.name}</option>)
                                            })}
                                        </Select>
                                    </div>
                                    <Button disabled={loading} color="primary" variant="raised" onClick={this.countHubspotVotes} style={{ marginBottom: 20, marginRight: 10 }}>Count Hubspot Votes</Button>
                                </>
                            }
                            {loading &&
                                <>
                                    <CircularProgress />
                                    <div style={{ marginBottom: 20 }}>
                                        <Typography variant="title">Counting votes, please wait...</Typography>
                                    </div>
                                </>
                            }
                            {!error && finished &&
                                <div>
                                    <Typography variant="title" style={{ marginBottom: 20 }}>Votation Results</Typography>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Votation Name</TableCell>
                                                <TableCell>Position Name</TableCell>
                                                <TableCell>Candidate Type</TableCell>
                                                <TableCell>Is Regional</TableCell>
                                                <TableCell>Region</TableCell>
                                                <TableCell>Candidate Name</TableCell>
                                                <TableCell>Candidate Votes</TableCell>
                                                <TableCell>Is Winner</TableCell>
                                            </TableRow>
                                        </TableHead>
                                    {this.state.response.map((row) => {
                                        return (
                                            <TableRow>
                                                <TableCell>{row.properties.votation_name}</TableCell>
                                                <TableCell>{row.properties.position_name}</TableCell>
                                                <TableCell>{row.properties.candidate_type}</TableCell>
                                                <TableCell>{row.properties.is_regional}</TableCell>
                                                <TableCell>{row.properties.region}</TableCell>
                                                <TableCell>{row.properties.candidate_name}</TableCell>
                                                <TableCell>{row.properties.candidate_votes}</TableCell>
                                                <TableCell>{row.properties.is_winner}</TableCell>
                                            </TableRow>
                                        )
                                    })}
                                    </Table>
                                </div>
                            }
                            {error &&
                                <div>
                                    <Typography variant="title" style={{ marginBottom: 20 }}>There was an error</Typography>
                                    <Typography variant="body1" style={{ marginBottom: 20 }}>{error}</Typography>
                                </div>
                            }
                        </Grid>
                    </Grid>
                </Drawer>
            </Grid>
        )
    }
}
