import React, { Component } from 'react';
import { Paper, Typography, FormGroup, Button, FormLabel, NativeSelect } from '@material-ui/core';
import Drawer from '../../components/drawer';
import Axios from 'axios';
import AuthHelperMethods from '../../components/AuthHelperMethods';
import { SnackbarProvider, withSnackbar } from 'notistack';

class Settings extends Component {

	constructor(props) {
		super(props);
		this.state = {
			country: 'US',
			chapter: '',
			countries: [],
			chapters: [],
			disabled: false
		};
	}

	componentWillMount() {
		this.getMappings();
	}

	getMappings = async () => {
		const auth = new AuthHelperMethods();
		let res = await Axios('/api/countries');
		const countries = await res.data;
		res = await Axios('/api/chapters');
		const chapters = await res.data;
		const { country, chapter } = auth.getProfile();
		this.setState({ countries, chapters, country, chapter });

	};

	handleChange = (name) => (e) => {
		this.setState({
			[name]: e.target.value
		});
	};

	save = async () => {
		this.setState({ disabled: true })
		const auth = new AuthHelperMethods();
		let profile = auth.getProfile();
		const { country, chapter } = this.state;
		profile = { ...profile, ...{ country, chapter } };
		const res = await Axios.post('/api/changeSettings', {
			profile
		});
		const data = await res.data;
		auth.setProfile(data.profile);
		this.setState({ disabled: false })
		const variant = "success";
		this.props.enqueueSnackbar('Changes has been saved!', { variant, vertical: "top" });
	}

	render() {
		const { chapters, countries, chapter, country, disabled } = this.state;

		return (
			<Drawer auth={this.props.auth}>
				<Typography variant='headline' style={{ marginBottom: 20 }}>
					Profile settings:
				</Typography>
				<Paper style={{ padding: 30, maxWidth: '50vw', width: 500 }}>

					<FormLabel>Preferred Country</FormLabel>
					<NativeSelect fullWidth style={{ marginBottom: 30 }} value={country} onChange={this.handleChange('country')}>
						<option value={false}>- None -</option> */}
									{countries.length && countries.map((v) => (
							<option key={v.id} value={v.id}>
								{v.name}
							</option>
						))}
					</NativeSelect>

					<FormGroup>
						<FormLabel>Preferred Chapter</FormLabel>
						<NativeSelect fullWidth style={{ marginBottom: 30 }} value={chapter} onChange={this.handleChange('chapter')}>
							<option value={false}>- None -</option> */}
									{chapters.length && chapters.map((v) => (
								<option key={v.id} value={v.id}>
									{v.name}
								</option>
							))}
						</NativeSelect>
					</FormGroup>
					<Button variant='contained' color='primary' disabled={disabled} onClick={this.save}>
						Save
							</Button>
				</Paper>
			</Drawer>
		);
	}
}

const MySettings = withSnackbar(Settings);

class SettingsStack extends Component {
	render() {
		return (
			<SnackbarProvider anchorOrigin={{ vertical: "top", horizontal: "right" }} maxSnack={3}>
				<MySettings auth={this.props.auth} />
			</SnackbarProvider>
		);
	}
}

export default SettingsStack;
