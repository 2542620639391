import { CircularProgress } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import React, { Component } from "react";
import { RowContext } from "./RowContextProvider";

class InnerRowTable extends Component {
  render() {
    const sub_columns = [
      { name: "email", label: "Email" },
      { name: "invcode", label: "Invitation Code" },
      { name: "first_name", options: { display: "excluded" } },
      { name: "last_name", options: { display: "excluded" } },
      {
        name: "name",
        label: "Name",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return tableMeta.rowData[2] + " " + tableMeta.rowData[3];
          },
        },
      },
      {
        name: "eligibility_status",
        label: "Status",
      }
    ];

    const sub_options = {
      sort: false,
      search: false,
      filter: false,
      download: false,
      print: false,
      columns: false,
      pagination: false,
      viewColumns: false,
      selectableRows: "none",
    };

    return (
      <RowContext.Consumer>
        {({ loading, innerTableData }) => {
          return loading ? (
            <CircularProgress disableShrink />
          ) : innerTableData.length > 0 ? (
            <div>
              <MUIDataTable
                options={sub_options}
                columns={sub_columns}
                data={innerTableData}
              />
            </div>
          ) : (
            "Not in Wordpress! Please export..."
          );
        }}
      </RowContext.Consumer>
    );
  }
}

export default InnerRowTable;
