import React, { Component, createContext } from "react";
import axios from "axios";

export const RowContext = createContext();

class RowContextProvider extends Component {
  constructor(props) {
    const { data, dataIndex } = props;
    super(props);
    this.state = {
      disabledButton: false || !data[4],
      innerTableData: [],
      data,
      dataIndex,
      loading: true,
    };
  }

  exportBtnHandler = async () => {
    const { setCurrentMembers } = this.props;
    const { data, dataIndex } = this.state;
    axios.get("/api/students/" + data[10]).then((res) => {
      var student = res.data;
      axios
        .post("/api/import_student", { student: student })
        .then((res) => {
          const tableData = res.data.members;
          if (tableData) {
            this.setInnerTableData(tableData);
            this.setButtonDisabled();
            setCurrentMembers(dataIndex, [...tableData]);
            alert("Saved In Wordpress Successfully");
          } else {
            alert("Something went wrong!");
          }
        })
        .catch((error) => {
          if (error.response) {
            document.getElementsByClassName("error-message")[0].innerText =
              error.response.data.desc;
          }
        });
    });
  };

  componentDidMount = () => {
    this.grabData(async () => {
      const { setCurrentMembers } = this.props;
      const { innerTableData, data, dataIndex } = this.state;

      if (innerTableData.length > 0) {
        this.setButtonDisabled();
      } else if (data[4]) {
        try {
          const res = await axios.get("/api/members/" + data[4]);
          const tableData = res.data.members;
          if (tableData.length > 0) {
            this.setInnerTableData(tableData);
            this.setButtonDisabled();
            setCurrentMembers(dataIndex, [...tableData]);
          }
        } catch (error) {
          console.log(error.message);
        }
      }
      this.setState({
        loading: false,
      });
    });
  };

  setButtonDisabled() {
    this.setState({
      disabledButton: true,
    });
  }

  setInnerTableData(data) {
    this.setState({ innerTableData: [...data] });
  }

  grabData = (next) => {
    const { dataIndex } = this.state;
    const { currentMembers } = this.props;
    const grabedData = currentMembers.filter(
      (elem) => elem.dataIndex === dataIndex
    );
    if (grabedData.length > 0) {
      this.setState({ innerTableData: [...grabedData[0].currentMember] }, next);
    } else {
      next();
    }
  };

  render() {
    return (
      <RowContext.Provider
        value={{
          ...this.state,
          exportBtnHandler: this.exportBtnHandler,
        }}
      >
        {this.props.children}
      </RowContext.Provider>
    );
  }
}

export default RowContextProvider;
