import React from "react";
import Grow from "@material-ui/core/Grow";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import { withStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";

const defaultStyles = (theme) => ({
  main: {
    display: "flex",
    flex: "1 0 auto",
  },
  searchIcon: {
    color: theme.palette.text.secondary,
    marginTop: "10px",
    marginRight: "8px",
  },
  searchText: {
    flex: "0.8 0",
  },
  clearIcon: {
    "&:hover": {
      color: theme.palette.error.main,
    },
  }  
});

class _TableSearch extends React.Component {
  constructor() {
    super();
    this.state = {
      searchText: "",
    };
  }

  handleTextChange = (e) => {
    this.setState({ searchText: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.setLoading(true);
    this.props.onSearch(this.state.searchText.trim());
  };

  render() {
    const { classes, options, onHide, isLoading } = this.props;
    return (
      <Grow appear in={true} timeout={300}>
        <form onSubmit={this.handleSubmit}>
          <div className={classes.main}>
            <SearchIcon className={classes.searchIcon} />
            <TextField
              className={classes.searchText}
              autoFocus={true}
              disabled={isLoading}
              value={this.state.searchText}
              onChange={this.handleTextChange}
              fullWidth={true}
              placeholder="search keywrod"
              {...(options.searchProps ? options.searchProps : {})}
            />
            {isLoading ? (
              <CircularProgress
                size={24}
                style={{ marginLeft: 15, position: "relative", top: 4 }}
              />
            ) : (
              <IconButton className={classes.clearIcon} onClick={onHide}>
                <ClearIcon />
              </IconButton>
            )}
          </div>
        </form>
      </Grow>
    );
  }
}

export const TableSearch = withStyles(defaultStyles, {
  name: "MUIDataTableSearch",
})(_TableSearch);

export function TableSearchRender(isLoading, setLoading) {
  return (searchText, handleSearch, hideSearch, options) => {
    return (
      <TableSearch
        searchText={searchText}
        onSearch={handleSearch}
        onHide={hideSearch}
        options={options}
        isLoading={isLoading}
        setLoading={setLoading}
      />
    );
  };
}