import React, { Component } from 'react';
import Drawer from '../../components/drawer';

import { Grid, Dialog, CircularProgress, Button, DialogContent, DialogTitle, DialogActions } from '@material-ui/core';
import Axios from 'axios';
import MUIDataTable from 'mui-datatables';
import Moment from 'react-moment';

var searchTimer;

export default class Approved extends Component {
	state = {
		users: [],
		loading: false,
		showDialog: false,
		done: false,
		total: 0,
		page: 0,
		order: 'id',
		orderDir: 'desc',
		search: null,
		ucode: null
	};

	componentDidMount() {
		this.getJobs();
	}

	async getJobs() {
		this.setState({ loading: true });
		const { match: { params } } = this.props;
		const {page, order, orderDir, search, ucode} = this.state;
		const res = await Axios.post(`/api/orm/users/approved-new`,
			{
				page,
				pageSize: 15,
				order,
				orderDir,
				search,
				ucode
			}
		);
		var users = await res.data.response;
		var total = await res.data.count;
		users = users.map((ele) => {
			var tmp = ele;
			tmp.ucode = ele.invcode.split('-')[0];
			tmp.primary_invitation = ele.invcode.split('-')[1];
			tmp.invitation_tracking = ele.invcode.split('-')[2];
			return tmp;
		});
		this.setState({ total, users, loading: false });
	}

	resendInvitation(rows) {
		const { users } = this.state;
		this.setState({ showDialog: true });
		const ids = rows.data.map((ele) => {
			return users[ele.dataIndex].id;
		});

		console.log(ids);
		const domain = `${window.location.protocol}//${window.location.host.split(':')[0]}`;
		Axios.post('/api/orm/resend', { ids, domain }).then((res) => {
			this.setState({ done: true });
			this.getJobs();
		});
	}

	handleDone = () => {
		this.setState({ showDialog: false, done: false });
	};

	render() {
		const { users, done } = this.state;

		const options = {
			filter: false,
			print: false,
			searchText: this.state.search,
			selectableRows: 'none',
			download: false,
			fixedHeaderOptions: {
				xAxis: false,
				yAxis: true
			},
			rowsPerPage: 15,
			serverSide: true,
			count: this.state.total,
			rowsPerPageOptions: [ 10, 15, 20, 50, 100 ],
			responsive: 'stacked',
			selectableRowsOnClick: 'none',
			customToolbarSelect: (selectedRows) => {
				const { data } = selectedRows;
				const { showDialog } = this.state;

				return (
					<div>
						<Button
							style={{ marginRight: 20 }}
							variant="contained"
							color="primary"
							onClick={() => this.resendInvitation(selectedRows)}
						>
							Resend invitation for {selectedRows.data.length} prospect(s)
							{showDialog && (
								<CircularProgress
									size={20}
									style={{ marginLeft: 5, marginRight: 5 }}
									color="secondary"
								/>
							)}
						</Button>
					</div>
				);
			},
			onTableChange: (action, tableState) => {
				console.log(action, tableState);

				if (action === 'sort') {
					const order = tableState.columns[0]
					this.setState({orderDir: order.sortDirection, page: 0}, () => {
						this.getJobs()
					})
				} else if (action === 'changePage') {
					this.setState({page: tableState.page}, () => {
						this.getJobs();
					})
				} else if (action === 'changeRowsPerPage') {
					this.setState({pageSize: tableState.rowsPerPage}, () => {
						this.getJobs()
					})
				} else if (action === 'search') {
					clearTimeout(searchTimer);
					searchTimer = setTimeout(() => {
						console.log(`I should search for ${tableState.searchText}`)
						this.setState({search: tableState.searchText}, () => {
							this.getJobs();
						})
					}, 600);
				} else if (action === 'filterChange') { 
					let ucode = tableState.filterList.filter(i => i.length>0)
					// this.setState({ucode: tableState})
					ucode = ucode[0] !== undefined ? ucode[0][0] : null
					this.setState({ucode}, () => {this.getJobs()})
				}
			}
		};

		const columns = [
			{
				name: 'id',
				label: 'ID',
				options: {
					sort: true,
					filter: false,
					sortDirection: this.state.orderDir
				}
			},
			{
				name: 'created_at',
				label: 'Created',
				options: {
					customBodyRender: (value, tableMeta, updateValue) => {
						const calendarStrings = {
							lastDay: '[Yesterday at] LT',
							sameDay: '[Today at] LT',
							nextDay: '[Tomorrow at] LT',
							lastWeek: '[last] dddd [at] LT',
							nextWeek: 'dddd [at] LT',
							sameElse: 'L'
						};
						return <Moment calendar={calendarStrings}>{value}</Moment>;
					},
					filter: false,
					sort: false
				}
			},
			{
				name: 'email',
				label: 'Email',
				options: {
					sort: false,
					filter: false
				}
			},
			{
				name: 'first_name',
				label: 'First Name',
				options: {
					sort: false,
					filter: false
				}
			},
			{
				name: 'middle_name',
				label: 'Middle Name',
				options: {
					sort: false,
					filter: false
				}
			},
			{
				name: 'last_name',
				label: 'Last Name',
				options: {
					sort: false,
					filter: false
				}
			},
			{
				name: 'ucode',
				label: 'Chapter Code',
				options: {
					sort: false,
					filter: true
				}
			},
			{
				name: 'contact_num',
				label: 'Contact Number',
				options: {
					sort: false,
					filter: false
				}
			},
			{
				name: 'primary_invitation',
				label: 'Primary Invitation',
				options: {
					sort: false,
					filter: false
				}
			},
			{
				name: 'invitation_tracking',
				label: 'Invitation tracking',
				options: {
					sort: false,
					filter: false
				}
			},
			// {
			// 	name: 'url',
			// 	label: 'Registration URL',
			// 	options: {
			// 		filter: false,
			// 		sort: false,
			// 		customBodyRender: function(value) {
			// 			return (
			// 				<a href={value} target="_blank">
			// 					{value}
			// 				</a>
			// 			);
			// 		}
			// 	}
			// }
		];

		return (
			<Grid container className="backgrounded">
				<Drawer auth={this.props.auth}>
					<Grid container>
						<Grid item md={12}>
							{this.state.loading && 
							<CircularProgress />
							}
							<MUIDataTable
								title={'Approved prospects List'}
								data={users}
								columns={columns}
								options={options}
							/>
						</Grid>
					</Grid>
				</Drawer>
				<Dialog
					onClose={this.handleClose}
					aria-labelledby="customized-dialog-title"
					open={this.state.showDialog}
					fullWidth="sm"
				>
					<DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
						Resending Invitation(s)
					</DialogTitle>
					{done || (
						<DialogContent dividers style={{ textAlign: 'center' }}>
							<CircularProgress style={{ margin: '0 auto' }} />
						</DialogContent>
					)}
					{done && [
						<DialogContent dividers>Invitation(s) has been sent successfully.</DialogContent>,
						<DialogActions>
							<Button onClick={this.handleDone} color="primary">
								Close
							</Button>
						</DialogActions>
					]}
				</Dialog>
			</Grid>
		);
	}
}
