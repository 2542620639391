import React, { Component } from "react";
import Drawer from "../../components/drawer";
import { Grid, CircularProgress, Typography } from "@material-ui/core";
import Axios from "axios";
import MUIDataTable from "mui-datatables";
import withAuth from "../../components/withAuth";
import Moment from "../../components/Moment";
import ExpandedRow from "./ExpandedRow";
import RowContextProvider from "./RowContextProvider";
import { TableSearchRender } from "./TableSearch";
class Students extends Component {
  state = {
    page: 0,
    count: 0,
    rowsPerPage: 100,
    students: [],
    isLoading: false,
    currentMembers: [],
    sortOrder: {},
    searchText: "",
    searching: false,
  };

  componentDidMount() {
    this.getStudents();
  }

  getStudents = async () => {
    this.setState({ isLoading: true });
    Axios.post("/api/students/find", {
      page: this.state.page,
      pageSize: this.state.rowsPerPage,
    }).then((res) => {
      this.setState({
        students: res.data.students,
        isLoading: false,
        count: res.data.total,
      });
    });
  };

  setLoading = (bool) => {
    this.setState({ isLoading: bool });
  };

  shouldComponentUpdate(_, nextState) {
    if (this.state.currentMembers !== nextState.currentMembers) {
      return false;
    } else {
      return true;
    }
  }

  changePage = (page, sortOrder, searchText) => {
    this.setState({
      isLoading: true,
    });
    Axios.post("/api/students/find", {
      page: page,
      pageSize: this.state.rowsPerPage,
      sortOrder,
      searchText,
    }).then((res) => {
      this.setState({
        isLoading: false,
        page: page,
        sortOrder,
        students: res.data.students,
        count: res.data.total,
      });
    });
  };

  sort = (page, sortOrder, searchText) => {
    this.setState({ isLoading: true });
    Axios.post("/api/students/find", {
      page: page,
      pageSize: this.state.rowsPerPage,
      sortOrder: sortOrder,
      searchText,
    }).then((res) => {
      this.setState({
        students: res.data.students,
        page,
        sortOrder,
        isLoading: false,
        count: res.data.total,
        searching: false,
      });
    });
  };

  setCurrentMembers = (dataIndex, currentMember) => {
    this.setState({
      currentMembers: [
        ...this.state.currentMembers,
        { dataIndex, currentMember },
      ],
    });
  };

  render() {
    const { count, students, isLoading, searchText, sortOrder } = this.state;

    const columns = [
      {
        name: "invcode",
        label: "Code",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              tableMeta.rowData[1] +
              "-" +
              (tableMeta.rowData[2] ? tableMeta.rowData[2] : "?") +
              "-" +
              (tableMeta.rowData[3] ? tableMeta.rowData[3] : "?")
            );
          },
        },
      },
      {
        name: "ucode",
        label: "ucode",
        options: { display: "excluded" },
      },
      {
        name: "activation_code",
        label: "activation_code",
        options: { display: "excluded" },
      },
      {
        name: "activation_tracking",
        label: "activation_tracking",
        options: { display: "excluded" },
      },
      {
        name: "email",
        label: "Email",
      },
      {
        name: "fname",
        label: "Name",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return value + " " + tableMeta.rowData[6]
              ? tableMeta.rowData[6]
              : "";
          },
        },
      },
      {
        name: "lname",
        label: "Last Name",
        options: { display: "excluded" },
      },
      {
        name: "eligibility_status",
        label: "Status",
      },
      {
        name: "jobID",
        label: "Job ID",
        options: { display: false },
      },
      {
        name: "created",
        label: "Created",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return <Moment>{value}</Moment>;
          },
        },
      },
      {
        name: "_id",
        label: "ID",
        options: { display: "excluded" },
      },
    ];

    const options = {
      sort: true,
      count: count,
      search: true,
      searchText,
      print: false,
      download: true,
      selectableRows: "none",
      filter: false,
      serverSide: true,
      sortOrder: sortOrder,
      tableBodyHeight: "fit-content",
      rowsPerPage: this.state.rowsPerPage,
      rowsPerPageOptions: [10, 100, 250, 500, 1000],
      responsive: "stacked",
      expandableRows: true,
      expandableRowsHeader: false,
      isRowExpandable: (index) => true,
      renderExpandableRow: (data, meta) => {
        return (
          <RowContextProvider
            data={data}
            dataIndex={meta.dataIndex}
            setCurrentMembers={this.setCurrentMembers}
            currentMembers={this.state.currentMembers}
          >
            <ExpandedRow />
          </RowContextProvider>
        );
      },
      customSearchRender: TableSearchRender(isLoading, this.setLoading),
      onTableChange: (action, tableState) => {
        switch (action) {
          case "changePage":
            this.changePage(
              tableState.page,
              this.state.sortOrder,
              tableState.searchText
            );
            break;
          case "sort":
            const announceText = tableState.announceText;
            const name = tableState.columns[tableState.activeColumn].name;
            const direction =
              name === this.state.sortOrder.name
                ? this.state.sortOrder.direction * -1
                : announceText.slice(announceText.indexOf(":") + 2) ===
                  "ascending"
                ? 1
                : -1;
            const sortOrder = {
              name,
              direction,
            };
            this.sort(tableState.page, sortOrder, tableState.searchText);
            break;
          case "search":
            this.setState({ searchText: tableState.searchText });
            this.sort(
              tableState.page,
              this.state.sortOrder,
              tableState.searchText
            );
            if (this.state.searching) {
            }
            break;
          case "changeRowsPerPage":
            this.setState({ rowsPerPage: tableState.rowsPerPage }, () =>
              this.getStudents()
            );
            break;
          default:
            return tableState;
        }
      },
    };

    return (
      <Drawer auth={this.props.auth}>
        <Grid
          container
          justify="flex-start"
          alignItems="center"
          style={{ marginTop: 10 }}
        >
          <Grid item md={12}>
            <MUIDataTable
              title={
                <Typography variant="h6">
                  Students
                  {isLoading && (
                    <CircularProgress
                      size={24}
                      style={{ marginLeft: 15, position: "relative", top: 4 }}
                    />
                  )}
                </Typography>
              }
              options={options}
              columns={columns}
              data={students}
            />
          </Grid>
        </Grid>
      </Drawer>
    );
  }
}

export default withAuth(Students);