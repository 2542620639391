import React, { Component } from 'react';
import {
	Paper,
	Grid,
	TextField,
	Typography,
	FormGroup,
	Button,
	Select,
	FormLabel,
	NativeSelect
} from '@material-ui/core';
import Drawer from '../../components/drawer';
import Axios from 'axios';
import AuthHelperMethods from '../../components/AuthHelperMethods';
import { SnackbarProvider, withSnackbar } from 'notistack';

class ChaptersAdd extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: '',
			name: '',
			disabled: false,
			success: false,
			error: false,
			edit: false
		};
	}

	componentDidMount() {
		if (this.props.match.params.hasOwnProperty('id')) {
			this.setState({ disabled: true, edit: true }, () => {
				Axios.get(`/api/settings/chapters/${this.props.match.params.id}`)
					.then((res) => {
						console.log(res.data);
						this.setState({ id: res.data.id, name: res.data.name, disabled: false });
					})
					.catch(() => {
						this.props.history.push('/chapters');
					});
			});
		}
	}

	render() {
		const { id, name, disabled } = this.state;
		return (
			<Drawer auth={this.props.auth}>
				<Typography variant="headline" style={{ marginBottom: 20 }}>
					Add a new Chapter:
				</Typography>
				{this.state.error && (
					<Typography variant="body2" color="secondary">
						Chapter already exists.
					</Typography>
				)}
				{this.state.success && (
					<Typography variant="body2" color="primary">
						Chapter saved, redirecting you back to chapters page.
					</Typography>
				)}
				<Paper style={{ padding: 30, maxWidth: '50vw', width: 500 }}>
					<FormGroup>
						<TextField
							disabled={disabled}
							id="id"
							name="id"
							autoFocus={true}
							label="Chapter code"
							onChange={this.changeID}
							value={this.state.id}
						/>
					</FormGroup>

					<FormGroup>
						<TextField
							disabled={disabled}
							id="name"
							name="name"
							label="Chapter name"
							onChange={this.changeID}
							value={this.state.name}
						/>
					</FormGroup>
					<Button
						variant="contained"
						color="primary"
						disabled={disabled}
						onClick={this.save}
						style={{ marginTop: 20 }}
					>
						Save
					</Button>
					{this.state.edit && (
						<Button
							variant="contained"
							color="secondary"
							disabled={disabled}
							onClick={this.delete}
							style={{ marginTop: 20 }}
						>
							Delete
						</Button>
					)}
				</Paper>
			</Drawer>
		);
	}

	delete = () => {
		const { id, name, edit } = this.state;
		if (this.props.match.params.hasOwnProperty('id')) {
			Axios.delete(`/api/settings/chapters/${this.props.match.params.id}`).then(() => {
				this.props.history.push('/chapters');
			});
		} else {
			this.props.history.push('/chapters');
		}
	};

	save = () => {
		this.setState({ error: false, disabled: true });
		const { id, name, edit } = this.state;
		Axios.post('/api/settings/chapters', { id, name, edit })
			.then((res) => {
				this.setState({ success: true, disabled: false }, () => {
					setTimeout(() => {
						this.props.history.push('/chapters');
					}, 2000);
				});
			})
			.catch(() => {
				this.setState({ error: true, disabled: false });
			});
	};
	changeID = (e) => {
		this.setState({ error: false });
		this.setState({ [e.target.name]: e.target.value });
	};
}

export default ChaptersAdd;
