import React, { Component } from 'react';
import Drawer from '../../components/drawer';

import { Grid, Dialog, CircularProgress, Button, DialogContent, DialogTitle, DialogActions } from '@material-ui/core';
import Axios from 'axios';
import MUIDataTable from 'mui-datatables';
import Link from 'react-router-dom';

var searchTimer;

export default class OfflineListing extends Component {
	state = {
		users: [],
		loading: false,
		showDialog: false,
		done: false,
		total: 0,
		page: 0,
		search: null
	};

	componentDidMount() {
		this.getJobs();
	}

	async getJobs() {
		this.setState({ loading: true });
		const { match: { params } } = this.props;
		const { page, search } = this.state;
		const res = await Axios.post(`/api/orm/offline/listing`,
			{
				page,
				pageSize: 15,
				search
			}
		);
		var users = await res.data.response;
		var total = await res.data.count;

		users = users.map((ele) => {
			var tmp = ele;
			tmp.used = tmp.used === 1 ? 'Yes' : 'No';
			return tmp;
		});
		this.setState({
			total,
			users,
			loading: false
		});
	}

	resendInvitation(rows) {
		console.log(rows);
		const { users } = this.state;
		this.setState({ showDialog: true });
		const ids = rows.data.map((ele) => {
			return users[ele.dataIndex].id;
		});

		console.log(ids);
		const domain = `${window.location.protocol}//${window.location.host.split(':')[0]}`;
		Axios.post('/api/orm/resend', { ids, domain }).then((res) => {
			this.setState({ done: true });
			this.getJobs();
		});
	}

	handleDone = () => {
		this.setState({ showDialog: false, done: false });
	};

	render() {
		const { users, done } = this.state;

		const options = {
			filterType: 'dropdown',
			selectableRows: false,
			selectableRowsOnClick: false,
			print: true,
			searchText: this.state.search,
			download: true,
			fixedHeader: true,
			rowsPerPage: 15,
			serverSide: true,
			count: this.state.total,
			rowsPerPageOptions: [10, 15, 20, 50, 100],
			responsive: 'stacked',
			onTableChange: (action, tableState) => {
				console.log(action, tableState);

				if (action == 'changePage') {
					this.setState({ page: tableState.page }, () => {
						this.getJobs();
					})
				} else if (action == 'changeRowsPerPage') {
					this.setState({ pageSize: tableState.rowsPerPage }, () => {
						this.getJobs()
					})
				} else if (action == 'search') {
					clearTimeout(searchTimer);
					searchTimer = setTimeout(() => {
						console.log(`I should search for ${tableState.searchText}`)
						this.setState({ search: tableState.searchText }, () => {
							this.getJobs();
						})
					}, 600);
				}
			}
		};

		const columns = ['invcode', 'used'];

		return (
			<Grid container className="backgrounded">
				<Drawer auth={this.props.auth}>
					<Grid container>
						<Grid item md={12}>
							{this.state.loading &&
								<CircularProgress />
							}
							<MUIDataTable
								title={'Offline invitations List'}
								data={users}
								columns={columns}
								options={options}
							/>
						</Grid>
					</Grid>
				</Drawer>
			</Grid>
		);
	}
}
