import React from "react";
import MUIDataTable from "mui-datatables";

class NonSavedStudentsTable extends React.Component {
  render() {
    const { data } = this.props;
    const columns = [
      {
        name: "invcode",
        label: "Code"
      },
      {
        name: "ucode",
        label: "University Code"
      },
      {
        name: "activation_code",
        label: "Activation Code"
      },
      {
        name: "activation_tracking",
        label: "Activation Tracking"
      },
      { 
        name: "email", label: "Email"
      },
      {
        name: "fname",
        label: "First Name"
      },
      {
        name: "lname",
        label: "Last Name"
      },
      {
        name: "eligibility_status",
        label: "Status"
      },
      { 
        name: "_id", label: "ID", options: { display: "excluded" } 
      }
    ];

    const options = {
      responsive: "stacked",
      selectableRows: "none",
    };

    return (
      <MUIDataTable
        title={"Not saved students."}
        data={data}
        columns={columns}
        options={options}
      />
    );
  }
}

export default NonSavedStudentsTable;
