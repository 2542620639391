import React, { Component } from 'react';
import Drawer from '../../components/drawer';
import {
	Grid,
	Paper,
	CircularProgress,
	Button,
	FormControlLabel,
	FormControl,
	MenuItem,
	Chip,
	Input,
	Select,
	Typography,
	RadioGroup,
	Radio,
	FormLabel} from '@material-ui/core';
import Axios from 'axios';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250
		}
	}
};

export default class Exporter extends Component {
	constructor() {
		super();
		this.state = {
			fields: [
				'ucode',
				'strategy',
				'studentid',
				'salutation',
				'suffix',
				'phone',
				'fname',
				'mname',
				'lname',
				'cstreet',
				'cstreet2',
				'ccity',
				'cstateprov',
				'cpostcode',
				'ccountry',
				'pstreet',
				'pstreet2',
				'pcity',
				'pstateprov',
				'ppostcode',
				'pcountry',
				'email',
				'email2',
				'activation_code',
				'activation_tracking',
				'eligibility_status'
			],
			duplicates: '0',
			exporting: false,
			exported: false,
			res: {}
		};
	}

	render() {
		let columns = [
			'ucode',
			'strategy',
			'studentid',
			'salutation',
			'suffix',
			'phone',
			'fname',
			'mname',
			'lname',
			'cstreet',
			'cstreet2',
			'ccity',
			'cstateprov',
			'cpostcode',
			'ccountry',
			'pstreet',
			'pstreet2',
			'pcity',
			'pstateprov',
			'ppostcode',
			'pcountry',
			'email',
			'email2',
			'activation_code',
			'activation_tracking',
			'eligibility_status'
		];

		const { fields, duplicates, exported, exporting, res } = this.state;
		const { match: { params: { jobid } } } = this.props;

		return (
			<Grid container className="backgrounded">
				<Drawer auth={this.props.auth}>
					<Typography variant="display1" style={{ marginBottom: 20 }}>
						Exporting {jobid}
					</Typography>
					<Paper style={{ padding: 40, maxWidth: '80%', margin: '0 auto' }}>
						<div style={{ display: 'flex', justifyContent: 'space-around' }}>
							<FormControl style={{ maxWidth: 320 }}>
								<FormLabel style={{ marginBottom: 10 }} component="legend">
									Fields to export
								</FormLabel>
								<Select
									multiple
									onChange={this.handleChangeMultiple}
									value={fields}
									input={<Input id="select-multiple-chip" />}
									renderValue={(selected) => (
										<div style={{ display: 'flex', flexWrap: 'wrap' }}>
											{selected.map((value) => (
												<Chip style={{ margin: '3px 5px' }} key={value} label={value} />
											))}
										</div>
									)}
									MenuProps={MenuProps}
								>
									{columns.map((name) => (
										<MenuItem key={name} value={name}>
											{name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							<FormControl style={{ maxWidth: 320 }}>
								<FormLabel style={{ marginBottom: 10 }} component="legend">
									Duplicates removal strategy
								</FormLabel>
								<RadioGroup
									aria-label="duplicates"
									name="duplicates"
									value={duplicates}
									onChange={this.handleChangeRadio}
								>
									<FormControlLabel value="0" control={<Radio color="primary" />} label="Keep all" />
									<FormControlLabel
										value="1"
										control={<Radio color="primary" />}
										label="Remove duplicates by email only"
									/>
									<FormControlLabel
										value="2"
										control={<Radio color="primary" />}
										label="Remove duplicates by first name and lastname combined"
									/>
									<FormControlLabel
										value="3"
										control={<Radio color="primary" />}
										label="Remove duplicates by All three, email, first name, and lastname combined"
									/>
								</RadioGroup>
							</FormControl>
						</div>
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								flexDirection: 'column',
								alignItems: 'center'
							}}
						>
							{exported && (
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'space-around',
										alignItems: 'center'
									}}
								>
									<Typography variant="body1">Excel file is ready.</Typography>
									<Button onClick={this.download} variant="contained">
										Download
									</Button>
									<Typography variant="body2">
										We found {res.real} records, exported {res.output} records per rules.
									</Typography>
								</div>
							)}
							<FormControl
								style={{ maxWidth: 320, marginTop: 20, display: 'flex', justifyContent: 'center' }}
							>
								<Button disabled={exporting} color="primary" variant="contained" onClick={this.export}>
									Export
									{exporting && <CircularProgress size={30} />}
								</Button>
								<Button
									disabled={exporting}
									color="secondary"
									variant="contained"
									style={{ marginTop: 20 }}
									onClick={() => {
										this.props.history.goBack();
									}}
								>
									Cancel
								</Button>
							</FormControl>
						</div>
					</Paper>
				</Drawer>
			</Grid>
		);
	}

	handleChangeMultiple = (event) => {
		const options = event.target.value;
		console.log(options, this.state.fields);
		this.setState({ fields: options });
	};

	handleChangeRadio = (event) => {
		this.setState({ duplicates: event.target.value });
	};

	export = () => {
		const { fields, duplicates } = this.state;
		const { match: { params: { jobid } } } = this.props;
		const body = {
			fields,
			duplicates,
			jobid
		};
		this.setState({ exporting: true });
		Axios.post('/api/jobs/export/new', body).then((res) => {
			const data = res.data;
			console.log(data);
			this.setState({ exporting: false, exported: true, res: res.data });
		});
	};

	download = () => {
		this.setState({ exported: false, exporting: false }, () => {
			window.location.href = this.state.res.url;
		});
	};
}
