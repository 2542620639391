import { TableCell, TableRow } from "@material-ui/core";
import React, { Component } from "react";
import ExpandedRowInfo from "./ExpandedRowInfo";
import InnerRowTable from "./InnerRowTable";
import { RowContext } from "./RowContextProvider";

class ExpandedRow extends Component {
  render() {
    return (
      <RowContext.Consumer>
        {({ data }) => {
          return (
            <TableRow>
              <TableCell colSpan={3}>
                <ExpandedRowInfo />
              </TableCell>
              <TableCell colSpan={3}>
                {!data[4] ? (
                  "This student does not have an email address!"
                ) : (
                  <InnerRowTable />
                )}
              </TableCell>
            </TableRow>
          );
        }}
      </RowContext.Consumer>
    );
  }
}

export default ExpandedRow;
